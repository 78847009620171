import axios from 'axios';
import { localStorageEnums } from '../constants/LocalStorageEnums';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'User-Agent': `timeZone/${(timeZone && timeZone) || ''}`,
    'X-Custom-user-agent': `timeZone/${(timeZone && timeZone) || ''} platform/web uniqueId/ version/1.0.`,
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(localStorageEnums.TOKEN);
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      window.localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default instance;
