import instance from "../Interceptor";
import { headers } from "../utils/headers";


export const AuthRegisterServices = async (formBody) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/auth/register`, {
        method: "POST",
        headers,
        data: JSON.stringify({
            "name": formBody.fullName,
            "email": formBody.email,
            "password": formBody.password,
            "using_for": "adult",
            "loc_lat": formBody.loc_lat,
            "loc_long": formBody.loc_long,
            "role": "organizer",
            "notification": false,
            "description": formBody.desc,
            "website": formBody.url,
            "phone": formBody.phone
        })
    });
    return response;
}

export const AuthLoginServices = async (formBody) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/auth/login`, {
        method: "POST",
        headers,
        data: JSON.stringify({
            email: formBody.email,
            password: formBody.password,
            role: ["admin", "organizer"]
        })
    });
    return response;
}

export const AuthForgotPasswordServices = async (formBody) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/auth/forgot-password`, {
        method: "POST",
        headers,
        data: JSON.stringify({
            email: formBody.email,
            role: "organizer"
        })
    });
    return response;
}

export const AuthUserInfoServices = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/user/info`, {
        method: "GET",
        headers
    });
    return response;
}

export const AuthUpdateUserInfoServices = async (formBody, lattitude, longitude) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/user/update`, {
        method: "POST",
        headers,
        data: JSON.stringify({
            using_for: formBody.usingFor,
            loc_lat: lattitude,
            loc_long: longitude
        })
    });
    return response;
}

export const AuthOTPVerificationServices = async (email, otp) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/auth/verify-otp`, {
        method: "POST",
        headers,
        data: JSON.stringify({
            email,
            otp: Number(otp)
        })
    });
    return response;
}

export const AuthResetPasswordServices = async (email, otp, values) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/auth/reset-password`, {
        method: "POST",
        headers,
        data: JSON.stringify({
            email,
            otp: Number(otp),
            password: values.password
        })
    });
    return response;
}


export const AuthChangePasswordServices = async (value) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/user/change-password`, {
        method: "POST",
        headers,
        data: JSON.stringify({
            password: value
        })
    });
    return response;
}
