import PropTypes from 'prop-types';
import React from 'react';
import swal from 'sweetalert';
import './Style.css';
import folder from '../Images/folder.png';
import { MESSAGES } from '../constants/ValidationMsgs';
import { fileExtensions, verifyFileExtension } from '../utils/validators';

const SliderImgUpload = ({ onChange }) => (
  <div className="sliderImgUpload">
    <label htmlFor="slider-upload" className="custom-file-upload_slider fas">
      <div className="sliderImgUpload_flex">
        <img htmlFor="slider-upload" src={folder} alt="img" width="100px" />
        <div className="slider_upload_details">
          <h3>Select your files * </h3>
          <span>Click to browse through your machines</span>
          <br />
          <span className="helper_text_msg">*Choose atmost 5 images</span>
        </div>
      </div>
      <input
        id="slider-upload"
        accept={fileExtensions.onlyImage}
        multiple
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => {
          onChange(e);
          e.target.value = '';
        }}
      />
    </label>
  </div>
);

SliderImgUpload.propTypes = {
  onChange: PropTypes.any,
};

const Edit = ({ onSubmit, children }) => (
  <div className="form_div_slider_upload">
    <form className="form_div_slider_upload" onSubmit={onSubmit}>
      {children}
    </form>
  </div>
);

Edit.propTypes = {
  children: PropTypes.any,
  onSubmit: PropTypes.any,
};

export const SliderUpload = ({
  setSliderImgState,
  sliderImgState,
  preloadedImgs,
  setPreloadedSliderImg,
  setDeletedSliderIndex,
  deletedSliderIndex,
}) => {
  const [sliderImg, setSliderImg] = React.useState({
    file: '',
    imagePreviewUrl: folder,
    name: '',
    status: '',
    active: 'edit',
  });

  const sliderPhotoUpload = (e) => {
    try {
      e.preventDefault();
      if (
        e.target.files.length <= 5 &&
        (sliderImgState.length + e.target.files.length + (preloadedImgs?.length || 0)) <= 5
      ) {
        const readerSlider = new FileReader();
        const file = e.target.files;
        const files = [];
        for (let i = 0; i < e.target.files.length; i += 1) {
          if (!e.target?.files[i].type.includes('image')) {
            swal('Info', MESSAGES.IMG_VALIDATIONS, 'info');
            return;
          }
          if (!verifyFileExtension(e.target?.files[i]?.name?.toLowerCase())) {
            swal('Info', MESSAGES.IMG_VALIDATIONS, 'info');
            return;
          }
          files.push(e.target?.files[i]);
        }
        setSliderImgState([...sliderImgState, ...files]);
        readerSlider.onloadend = () => {
          setSliderImg({
            ...sliderImg,
            file,
            imagePreviewUrl: readerSlider.result,
          });
        };
        readerSlider.readAsDataURL(file);
      } else {
        swal('Info', 'You can upload atmost 5 images only.', 'info');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSliderImgSubmit = (e) => {
    e.preventDefault();
    const activeP = sliderImg.active === 'edit' ? 'profile' : 'edit';
    setSliderImg({
      ...sliderImg,
      active: activeP,
    });
  };

  const deleteImg = (e, id) => {
    e.preventDefault();
    const files = [];
    for (let i = 0; i < sliderImgState.length; i += 1) {
      if (i !== id) {
        files.push(sliderImgState[i]);
      }
    }
    setSliderImgState(files);
  };

  const deletePreloadedImg = (e, id) => {
    e.preventDefault();
    const files = [];
    for (let i = 0; i < preloadedImgs.length; i += 1) {
      if (preloadedImgs[i].id !== id) {
        files.push(preloadedImgs[i]);
      } else {
        setDeletedSliderIndex([...deletedSliderIndex, id]);
      }
    }
    setPreloadedSliderImg(files);
  };

  return (
    <div className="sliderPhotoUpload">
      {sliderImg.active === 'edit' && (
        <Edit onSubmit={handleSliderImgSubmit}>
          <SliderImgUpload onChange={sliderPhotoUpload} src={sliderImg.imagePreviewUrl} />
          <div className="preview_card_div">
            {preloadedImgs?.map((img, index) => (
              <div className="preview_card" key={index} style={{ backgroundColor: `#ccc` }}>
                <button type="button" className="preview_card_close" onClick={(e) => deletePreloadedImg(e, img.id)}>
                  X
                </button>
                <img
                  alt="img"
                  src={
                    img?.src
                      ? `${process.env.REACT_APP_CLOUDFRONT}/${img.src}`
                      : `${process.env.REACT_APP_CLOUDFRONT}/${img.image}`
                  }
                  className="preview_card_img"
                />
              </div>
            ))}
            {sliderImgState?.map((url, index) => (
              <div className="preview_card" key={index} style={{ backgroundColor: `#ccc` }}>
                <button type="button" className="preview_card_close" onClick={(e) => deleteImg(e, index)}>
                  X
                </button>
                <img alt="img" src={URL.createObjectURL(url)} className="preview_card_img" />
              </div>
            ))}
          </div>
        </Edit>
      )}
    </div>
  );
};

SliderUpload.propTypes = {
  setSliderImgState: PropTypes.any,
  sliderImgState: PropTypes.any,
  preloadedImgs: PropTypes.any,
  setPreloadedSliderImg: PropTypes.any,
  setDeletedSliderIndex: PropTypes.any,
  deletedSliderIndex: PropTypes.any,
};
