export const googleAutocompleteStyles = {
    control: () => ({
        border: '1px solid #ccc',
        display: 'flex',
        borderRadius: '5px',
        minHeight: '52px',
        cursor: 'pointer'
    }),
    input: () => ({
        color: 'black',
        focus: 'yellow'
    }),
    menu: () => ({
        position: 'absolute',
        minWidth: '100%',
        boxShadow: '0 0 5px #ccc',
        backgroundColor: 'white',
        zIndex: '999999'
    }),
    option: () => ({
        color: 'black',
        backgroundColor: 'white',
        zIndex: '9999'
    }),
    singleValue: () => ({
        color: 'black',
    }),
}