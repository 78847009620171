import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import USERLIST from '../_mock/user';
import { useStyles } from '../Styles/components';
import { insertBroadcasts } from '../store/features/Data';
import "../Styles/Slider.css";
import "../Styles/BroadcastPage.css";
import { getNotificationList } from '../Services/Admin.services';
import { abbreviate } from '../utils/abbreviators';

const TABLE_HEAD = [
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'desc', label: 'Description', alignRight: false },
    { id: 'recipient', label: 'Recipient', alignRight: false }
];


export const BroadcastPage = () => {

    const [spinner, setSpinner] = useState(false);

    const dispatch = useDispatch();

    const [count, setCount] = useState(0);

    const broadcasts = useSelector((state) => state.dataManager.broadcasts);

    const classes = useStyles();

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('title');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [broadcastList, setBroadcastList] = useState([]);

    useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                const response = await getNotificationList();
                if (response.status === 200) {
                    const data = await response.data;
                    setBroadcastList(data?.data?.notifications || []);
                    dispatch(insertBroadcasts(data?.data || []));
                    setCount(data?.data?.count || 0);
                }
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        if (broadcasts?.length === 0) fetchList();
        else {
            setBroadcastList(broadcasts?.notifications || []);
            setCount(broadcasts?.count || 0);
        }
        // eslint-disable-next-line
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const filteredUsers = broadcastList?.filter((broadcast) =>
        broadcast?.title?.toLowerCase().includes(filterName?.toLowerCase()) || broadcast?.description?.toLowerCase().includes(filterName?.toLowerCase())
    );

    const isUserNotFound = filteredUsers?.length === 0;


    return (
        <Page title="Broadcasts" goBack={false}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Broadcast Notifications
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/dashboard/create-broadcast-notification"
                        startIcon={<Icon icon='ic:baseline-broadcast-on-personal' />}
                    >
                        Create Broadcast
                    </Button>

                </Stack>

                <Card>
                    <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                        title="Broadcast"
                    />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={USERLIST.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                                        const { id, title, description, organizationName } = row;
                                        const isItemSelected = selected.indexOf(title) !== -1;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell component="th" scope="row" padding="2px">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Icon icon="zondicons:announcement" />
                                                        <Typography variant="subtitle2" noWrap title={title}>
                                                            {abbreviate(title,35)}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left" title={description}>{abbreviate(description,35)}</TableCell>
                                                <TableCell align="left" title={organizationName||"-"}>{organizationName ? abbreviate(organizationName,30) : "-"}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {spinner && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <div className={classes.fullWidth}>
                                                    <CircularProgress className={classes.textAlign} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                                {!spinner && isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound title="No Broadcasts Found." />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
};
