import React, { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbarWithFilter } from '../sections/@dashboard/user';
import USERLIST from '../_mock/user';
import { useStyles } from '../Styles/components';
import { fetchGraphDetailsById } from '../Services/Dashboard.services';
import { capitalizeFirstLetter } from '../utils/abbreviators';
import { fetchOrganizersList } from '../Services/Admin.services';
import "../Styles/DashboardApp.css";
import { ROLES } from '../constants/Roles';
import { localStorageEnums } from '../constants/LocalStorageEnums';

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'date', label: 'Registered On', alignRight: false },
    { id: 'type', label: 'Event Type', alignRight: false },
    { id: 'organizer', label: 'Organizer Name', alignRight: false }
];

const TABLE_HEAD_ORG = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'date', label: 'Registered On', alignRight: false },
    { id: 'type', label: 'Event Type', alignRight: false }
];

export default function GraphListById() {
    const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));
    const { id, title } = useParams();
    const startDate = window.location.search.split("&")[0].split("=")[1];
    const endDate = window.location.search.split("&")[1].split("=")[1];
    const isAdmin = userData?.role === ROLES.ADMIN;

    const [count, setCount] = useState(0);
    const classes = useStyles();

    const [page, setPage] = useState(0);

    const [spinner, setSpinner] = useState(true);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [organizerList, setOrganizerList] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [organizerId, setOrganizerId] = useState('');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [clicksList, setClicksList] = useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    React.useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                const response = await fetchGraphDetailsById(id, page, organizerId, filterName, startDate, endDate);
                const data = await response.data;
                setClicksList(data?.data?.data)
                setCount(data?.data?.count);
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        if (id) {
            fetchList();
        }
        // eslint-disable-next-line
    }, [id, organizerId]);

    useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                const response = await fetchOrganizersList();
                if (response.status === 200) {
                    const data = await response.data;
                    setOrganizerList(data.data.data);
                }
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        fetchList();
        // eslint-disable-next-line
    }, []);


    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
        try {
            setSpinner(true);
            const response = await fetchGraphDetailsById(id, newPage, organizerId, filterName, startDate, endDate);
            const data = await response.data;
            setClicksList([...data?.data?.data]);
            setCount(data?.data?.count);
            setSpinner(false);
        } catch (err) {
            setSpinner(false);
            swal('Error', err.response?.data?.message || "", 'error');
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredUsers = clicksList;

    const isUserNotFound = filteredUsers?.length === 0;

    const changeHandler = async (event) => {
        setFilterName(event.target.value);
        setClicksList([]);
        setPage(0);
        try {
            setSpinner(true);
            const response = await fetchGraphDetailsById(id, page, organizerId, event.target.value, startDate, endDate);
            const data = await response.data;
            setClicksList(data?.data?.data)
            setCount(data?.data?.count);
            setSpinner(false);
        } catch (err) {
            setSpinner(false);
            swal('Error', err.response?.data?.message || "", 'error');
        }
    };

    // eslint-disable-next-line
    const debouncedChangeHandler = useMemo(() => {
        return debounce(changeHandler, 600);
        // eslint-disable-next-line
    }, []);


    return (
        <Page title="Graph" goBack={false}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {title}
                    </Typography>
                </Stack>
                <Card>
                    <UserListToolbarWithFilter
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={debouncedChangeHandler}
                        title="Users"
                    >
                        {userData?.role === ROLES.ADMIN && <div style={{ display: 'flex', flexDirection: 'flex-end', marginLeft: '15px' }}>
                            <FormControl className='organizer_filter_select'>
                                <InputLabel id="demo-simple-select-label">Filter by Organizer</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={organizerId}
                                    label="Filter by Organizer"
                                    onChange={(e) => setOrganizerId(Number(e.target.value))}
                                >
                                    {organizerList?.map(organizer => <MenuItem key={organizer?.user?.id} value={organizer?.user?.id}>{organizer.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                            {organizerId && <Button onClick={() => setOrganizerId("")}>Clear</Button>}
                        </div>}
                    </UserListToolbarWithFilter>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={isAdmin ? TABLE_HEAD : TABLE_HEAD_ORG}
                                    rowCount={USERLIST.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers?.map((row) => {
                                        const { id, name, email, eventType, organizerName, eventRegisteredOn, organizerIsDeactivated } = row;
                                        const isItemSelected = selected.indexOf(name) !== -1;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell component="th" scope="row" padding="2px">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Avatar className={classes.avatar} alt={name} src="" />
                                                        <Typography variant="subtitle2" noWrap style={{ color: organizerIsDeactivated ? 'red' : 'inherit' }}>
                                                            {capitalizeFirstLetter(name)}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left" style={{ color: organizerIsDeactivated ? 'red' : 'inherit' }}>{email}</TableCell>
                                                <TableCell align="left" style={{ color: organizerIsDeactivated ? 'red' : 'inherit' }}>{moment(eventRegisteredOn).format('llll')}</TableCell>
                                                <TableCell align="left" style={{ color: organizerIsDeactivated ? 'red' : 'inherit' }}>{capitalizeFirstLetter(eventType)}</TableCell>
                                                {isAdmin && <TableCell align="left" style={{ color: organizerIsDeactivated ? 'red' : 'inherit' }}>{organizerName}</TableCell>}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {spinner && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <div className={classes.fullWidth}>
                                                    <CircularProgress className={classes.textAlign} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                                {!spinner && isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound title="No Users Found." />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
