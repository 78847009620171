import PropTypes from 'prop-types';
import React from 'react'
import swal from 'sweetalert';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import OtpInput from 'react-otp-input';
import { privacyAbbreviation } from '../../utils/formatTypography';
import "./poppupStyles.css";
import { AuthForgotPasswordServices, AuthOTPVerificationServices } from '../../Services/Auth.services';
import { inputStyle, useStyles } from '../../Styles/components';
import { isValidEmail } from '../../utils/validators';
import { MESSAGES } from '../../constants/ValidationMsgs';

export const OtpModal = (props) => {

  const [spinner, setSpinner] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const classes = useStyles();
  const [otp, setOtp] = React.useState("");
  const handleChange = (otp) => {
    setOtp(otp);
    props?.saveOtp(otp);
  }

  const resendOTP = async () => {
    if (isValidEmail(props?.email) && !spinner) {
      setSpinner(true);
      try {
        const response = await AuthForgotPasswordServices({ email: props?.email });
        setSpinner(false);
        if (response.status === 201) {
          swal("Success", MESSAGES.RESEND_OTP_SUCCESS, "success");
        }
      } catch (err) {
        setSpinner(false);
        swal('Error', err.response?.data?.message, 'error');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (otp) {
        setLoader(true);
        const response = await AuthOTPVerificationServices(props?.email, otp);
        if (response.status === 201) {
          setLoader(false);
          props?.setOpenRestPWDModal(true);
          setOtp('');
        }
      }
    } catch (err) {
      setLoader(false);
      swal("Invalid OTP", "", "error");
    }
  }
  return (
    <div className='login_div'>
      <div className={classes.assignmentGrid}>
        <h1>Enter the OTP</h1>
        <p>Sent on your email {privacyAbbreviation(props?.email, 5)}</p>
        <OtpInput
          value={otp}
          onChange={handleChange}
          isInputNum="true"
          numInputs={4}
          separator={<span>-</span>}
          inputStyle={inputStyle}
        />
        <div className='otp_poppup_flex_row'>
          <Button onClick={resendOTP} className={classes.buttonMargin} variant='contained'>
            {spinner ? <CircularProgress size={"24px"} style={{ color: "white" }} /> :
              "Resend OTP"
            }</Button>
          <Button onClick={handleSubmit} className={classes.buttonMargin} variant='contained'>{loader ? <CircularProgress size={"24px"} style={{ color: "white" }} /> :
            "Verify Account"
          }</Button>
        </div>
      </div>
    </div>
  )
}



OtpModal.propTypes = {
  setOpenRestPWDModal: PropTypes.any,
  email: PropTypes.any,
  saveOtp: PropTypes.any
};