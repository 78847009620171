import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import swal from 'sweetalert';
import Iconify from '../../../components/Iconify';
import { deactivateOrganizerById, verifyOrganizerById } from '../../../Services/Admin.services';

UserMoreMenu.propTypes = {
  id: PropTypes.string,
  fetchOrganizers: PropTypes.any,
  isVerified: PropTypes.any,
  isDeactivated: PropTypes.any,
};

export default function UserMoreMenu({ id, fetchOrganizers, isVerified, isDeactivated }) {

  const [spinner, setSpinner] = useState(false);


  const verifyUser = async () => {
    try {
      setSpinner(true);
      const verifyOganizer = await verifyOrganizerById(id);
      const data = await verifyOganizer.data;
      setSpinner(false);
      if (verifyOganizer.status === 201) {
        swal('Success', data.message, 'success').then(() => fetchOrganizers());
      }
    } catch (err) {
      setSpinner(false);
      swal('Error', err.response?.data?.message || "", 'error');
    }
  };

  const blockUser = async () => {
    try {
      setSpinner(true);
      const deactivateUserResponse = await deactivateOrganizerById(id, !isDeactivated);
      const data = await deactivateUserResponse.data;
      setSpinner(false);
      if (deactivateUserResponse.status === 201) {
        swal('Success', data.message, 'success').then(() => fetchOrganizers());
      }
    } catch (err) {
      setSpinner(false);
      swal('Error', err.response?.data?.message || "", 'error');
    }
  };

  return (
    <>
      {!isVerified ? <Button onClick={verifyUser} sx={{ color: 'text.secondary' }} style={{ maxWidth: '30px' }} title="Click to approve organizer">
        {spinner ? <CircularProgress /> :
          <Iconify icon="line-md:circle-to-confirm-circle-transition" width={24} height={24} />
        }
      </Button> :
        <Button onClick={blockUser} sx={{ color: 'text.secondary' }} style={{ maxWidth: '30px' }} title={ isDeactivated ? "Click to Activate Organizer" : "Click to Deactivate Organizer"}>
          {spinner ? <CircularProgress /> :
            <Iconify icon={!(isDeactivated && isVerified) ? "material-symbols:block" : "mdi:account-reactivate"} width={24} height={24} />}
        </Button>
      }
    </>
  );
}
