import * as React from "react";
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Chip, CircularProgress, Stack, Typography } from '@mui/material';
import { fShortenNumber } from '../../../utils/formatNumber';
import Iconify from '../../../components/Iconify';

const IconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

AppWidgetSummaryWithFilter.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.string,
    toggle: PropTypes.string,
    total: PropTypes.number.isRequired,
    sx: PropTypes.object,
    loading: PropTypes.any
};

export default function AppWidgetSummaryWithFilter({ total, icon, loading, color = 'secondary', toggle = false, sx, ...other }) {
    const [userToggles, setUserToggles] = React.useState(false);
    const handleClick = (e) => {
        setUserToggles(e);
    }
    return (
        <Card
            sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                color: (theme) => theme.palette[color].darker,
                bgcolor: (theme) => theme.palette[color].lighter,
                ...sx,
            }}
            {...other}
        >
            {toggle && <Stack position="absolute" left="5%" direction="row" justifyContent="space-between" width="90%" spacing={0}>
                <Chip label="Daily" color="secondary" variant={!userToggles ? "outlined" : "filled"} onClick={() => handleClick(true)} />
                <Chip label="Monthly" color="secondary" variant={userToggles ? "outlined" : "filled"} onClick={() => handleClick(false)} />
            </Stack>}
            <IconWrapperStyle
                sx={{
                    color: (theme) => theme.palette[color].dark,
                    backgroundImage: (theme) =>
                        `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
                            theme.palette[color].dark,
                            0.24
                        )} 100%)`,
                }}
            >
                <Iconify icon={icon} width={24} height={24} />
            </IconWrapperStyle>
            {loading ? <CircularProgress color={color} /> :
                <Typography variant="h3">{fShortenNumber(userToggles ? total : (total * 10 + 6))}</Typography>}

            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                {userToggles ? "Daily Users" : "Monthly Users"}
            </Typography>
        </Card>
    );
}
