import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Sentry from '@sentry/react';
import swal from 'sweetalert';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { AuthLoginServices } from '../../../Services/Auth.services';
import { localStorageEnums } from '../../../constants/LocalStorageEnums';
import { ForgotPasswordEmailModal } from '../../../components/popups/forgotPasswordPopUp';

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: window.localStorage.getItem(localStorageEnums.EMAIL) || '',
    password: window.localStorage.getItem(localStorageEnums.PASSWORD) || '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (e) => {
    try {
      const response = await AuthLoginServices(e);
      const data = await response.data;
      if (response.status === 201) {
        if (e.remember) {
          window.localStorage.setItem(localStorageEnums.EMAIL, e.email);
          window.localStorage.setItem(localStorageEnums.PASSWORD, e.password);
        } else {
          window.localStorage.removeItem(localStorageEnums.EMAIL);
          window.localStorage.removeItem(localStorageEnums.PASSWORD);
        }
        const { accessToken, user } = data?.data;
        window.localStorage.setItem(localStorageEnums.TOKEN, accessToken);
        window.localStorage.setItem(localStorageEnums.USERDATA, JSON.stringify(user));
        if (accessToken) {
          Sentry.setUser({
            id: user?.id,
            name: user?.name,
            email: user?.name,
          });
        }
        navigate('/', { replace: true });
      }
    } catch (err) {
      swal('Error', err.response?.data?.message || '', 'error');
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <ForgotPasswordEmailModal handleClose={handleClose} handleOpen={handleOpen} open={open} />
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="column" sx={{ my: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <RHFCheckbox name="remember" label="Remember me" />
          <Link variant="subtitle2" underline="hover" onClick={() => setOpen(true)}>
            Forgot password?
          </Link>
        </Stack>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
