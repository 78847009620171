import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import swal from 'sweetalert';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { SliderUploadFiles } from '../components/SliderUploadFiles';
import Page from '../components/Page';
import { CardProfile } from '../components/ProfileImageCard';
import { localStorageEnums } from '../constants/LocalStorageEnums';
import SimpleBackdrop from '../components/Backdrop';
import { isValidEmail } from '../utils/validators';
import { MESSAGES } from '../constants/ValidationMsgs';
import { fetchOrganizerInfoByID, updateOrganizer } from '../Services/Organizer.services';
import "../Styles/Slider.css";
import { insertOrganizerProfile } from '../store/features/Data';
import { googleAutocompleteStyles } from '../Styles/googleAutocompleteStyling';

export const OganizerProfile = () => {

  const dispatch = useDispatch();

  const organizerProfile = useSelector((state) => state.dataManager.organizerProfile);

  const [spinner, setSpinner] = useState(false);
  const userData = JSON.parse(window.localStorage.getItem("userData"));

  const [organizerData, setOrganizerData] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
    addressLine1: '',
    addressLine2: '',
  });

  const [apiPlaceData, setApiPlaceData] = useState();


  const [addressData, setAddressData] = useState({
    address: '',
    city: '',
    country: '',
    venue: '',
    state: '',
    postcode: '',
    lat: '',
    long: '',
  });


  const [checkBoxData, setCheckBoxData] = useState({
    league: false,
    pickup: false,
    tournament: false
  });

  const [profileImg, setProfileImg] = useState('');
  const [sliderImg, setSliderImg] = useState([]);
  const [preloadedSliderImg, setPreloadedSliderImg] = useState([]);
  const [deletedSliderIndex, setDeletedSliderIndex] = useState([]);

  const formdata = new FormData();

  const [errors, setErrors] = React.useState({
    name: false,
    email: false,
    phone: false,
    website: false,
    addressLine1: false
  });

  const [addressError, setAddressError] = React.useState({
    address: false,
    city: false,
    country: false,
    venue: false,
    state: false,
    postcode: false,
    lat: false,
    long: false
  });

  React.useEffect(() => {
    const fetchOrganizerById = async () => {
      try {
        setSpinner(true);
        const response = await fetchOrganizerInfoByID(userData?.id);
        const data = await response.data;
        const { name = "", email = "", phone = "", website = "", addressLine1 = "", addressLine2 = "", description = "", organizerLogo = "", organizerImage = [], sportsEvent = [], address = "", city = '',
          country = '',
          venue = '',
          state = '',
          postcode = '',
          lat = '',
          long = '', } = data?.data?.organizer;
        dispatch(insertOrganizerProfile(data?.data?.organizer || []));
        setOrganizerData({
          name,
          email,
          phone,
          website,
          addressLine1,
          addressLine2,
          description,
          organizerLogo,
          organizerImage
        });
        setAddressData({
          address,
          city,
          country,
          venue,
          state,
          postcode,
          lat,
          long,
        })
        setPreloadedSliderImg(organizerImage);
        setCheckBoxData({
          league: sportsEvent?.name?.includes("league"),
          pickup: sportsEvent?.name?.includes("pickup"),
          tournament: sportsEvent?.name?.includes("tournament"),
        });
        setSpinner(false);
      } catch (err) {
        setSpinner(false);
        swal("Error", MESSAGES.SOMETHING_WENT_WRONG, "error");
      }
    }
    if (userData?.id !== undefined && Object.keys(organizerProfile).length === 0 && Object.getPrototypeOf(organizerProfile) === Object.prototype) {
      fetchOrganizerById();
    }
    else if (userData?.id !== undefined && Object.keys(organizerProfile).length !== 0) {
      setOrganizerData({
        name: organizerProfile?.name || '',
        email: organizerProfile?.email || '',
        phone: organizerProfile?.phone || '',
        website: organizerProfile?.website || '',
        addressLine1: organizerProfile?.addressLine1 || '',
        addressLine2: organizerProfile?.addressLine2 || '',
        description: organizerProfile?.description || '',
        organizerLogo: organizerProfile?.organizerLogo || '',
        organizerImage: organizerProfile?.organizerImage || ''
      });
      setAddressData({
        address: organizerProfile?.address,
        city: organizerProfile?.city,
        country: organizerProfile?.country,
        venue: organizerProfile?.venue,
        state: organizerProfile?.state,
        postcode: organizerProfile?.postcode,
        lat: organizerProfile?.latitude,
        long: organizerProfile?.longitude,
      })
      setPreloadedSliderImg(organizerProfile?.organizerImage || []);
      setCheckBoxData({
        league: organizerProfile?.sportsEvent?.name?.includes("league"),
        pickup: organizerProfile?.sportsEvent?.name?.includes("pickup"),
        tournament: organizerProfile?.sportsEvent?.name?.includes("tournament"),
      })
    }
    // eslint-disable-next-line
  }, [userData?.id]);


  const fetchOrganizerById = async () => {
    setSpinner(true);
    const response = await fetchOrganizerInfoByID(userData?.id);
    const data = await response.data;
    const { name = "", email = "", phone = "", website = "", addressLine1 = "", addressLine2 = "", description = "", organizerLogo = "", organizerImage = [], sportsEvent = [] } = data?.data?.organizer;
    dispatch(insertOrganizerProfile(data?.data?.organizer || []));
    setOrganizerData({
      name,
      email,
      phone,
      website,
      addressLine1,
      addressLine2,
      description,
      organizerLogo,
      organizerImage
    });
    setPreloadedSliderImg(organizerImage || []);
    setCheckBoxData({
      league: sportsEvent?.name?.includes("league"),
      pickup: sportsEvent?.name?.includes("pickup"),
      tournament: sportsEvent?.name?.includes("tournament"),
    })
    setSpinner(false);
  }

  const handleChange = (e, prop) => {
    if (prop === "phone") {
      setOrganizerData({
        ...organizerData,
        phone: e.target.value.trim()
      })
      setErrors({
        ...errors,
        phone: e.target.value.length !== 10
      })
    }
    else if (prop === "email") {
      setOrganizerData({
        ...organizerData,
        email: e.target.value
      })
      setErrors({
        ...errors,
        email: !isValidEmail(e.target.value)
      })
    }
    else {
      setOrganizerData({
        ...organizerData,
        [prop]: e.target.value.trim().length > 0 ? e.target.value : e.target.value.trim()
      })
      setErrors({
        ...errors,
        [prop]: !e.target.value.trim()
      })
    }
  }



  const handleChangeAddress = (e, prop) => {
    setAddressData({
      ...addressData,
      [prop]: e.target.value,
    });
    setErrors({
      ...errors,
      [prop]: !e.target.value,
    });
  };


  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (organizerData.name.trim() && isValidEmail(organizerData.email) && organizerData?.phone?.length === 10 && organizerData?.website?.trim() && addressData.city?.trim() && addressData.country?.trim() && addressData.lat !== 0 && addressData.long !== 0 && addressData.postcode.trim() && addressData.state?.trim()) {
        setSpinner(true);
        formdata.append('name', organizerData.name);
        formdata.append('email', organizerData.email);
        formdata.append('address_line_1', addressData.address || "");
        formdata.append('address', addressData.address);
        formdata.append('city', addressData.city);
        formdata.append('postcode', addressData.postcode);
        formdata.append('country', addressData.country);
        formdata.append('state', addressData.state);
        formdata.append('latitude', Number(addressData.lat));
        formdata.append('longitude', Number(addressData.long));
        formdata.append('address_line_2', organizerData.addressLine2 || "");
        formdata.append('phone', organizerData.phone);
        formdata.append('website', organizerData.website);
        formdata.append('description', organizerData.description);

        if (checkBoxData.league)
          formdata.append('sports_events[]', "league");

        if (checkBoxData.pickup)
          formdata.append('sports_events[]', "pickup");

        if (checkBoxData.tournament)
          formdata.append('sports_events[]', "tournament");

        formdata.append('organizer_sport[]', "1");

        formdata.append('organizer_logo', profileImg);

        for (let i = 0; i < sliderImg.length; i += 1)
          formdata.append('slider_images', sliderImg[i]);
        for (let i = 0; i < deletedSliderIndex.length; i += 1)
          formdata.append('delete_slider_images[]', deletedSliderIndex[i]);

        const response = await updateOrganizer(formdata, { Authorization: `Bearer ${window.localStorage.getItem(localStorageEnums.TOKEN)}` });
        const data = await response.data;
        setSpinner(false);
        if (response.status === 201)
          swal('Success', data.message, 'success').then(() => {
            window.localStorage.setItem(localStorageEnums.USERDATA, JSON.stringify(data.data.user));
            window.location.reload();
            setSliderImg([]);
            setPreloadedSliderImg([]);
            fetchOrganizerById();
          });
      }
      else {
        setErrors({
          name: !organizerData?.name?.trim(),
          email: !isValidEmail(organizerData.email),
          phone: organizerData.phone.length !== 10,
          website: !organizerData.website?.trim(),
          addressLine1: !organizerData?.addressLine1?.trim(),
        })
        setAddressError({
          address: !addressData.address?.trim(),
          city: !addressData.city?.trim(),
          country: !addressData.country?.trim(),
          venue: !addressData.venue?.trim(),
          state: !addressData.state?.trim(),
          postcode: !addressData.postcode?.trim(),
          lat: addressData.lat === 0,
          long: addressData.long === 0
        });
      }
    } catch (err) {
      setSpinner(false);
      console.log(err);
      swal('Error', MESSAGES.SOMETHING_WENT_WRONG, 'error');
    }
  };


  React.useEffect(() => {
    if (apiPlaceData) {
      geocodeByPlaceId(apiPlaceData.value.place_id)
        .then(results => {
          const countryObj = results[0].address_components.filter((address) => address.types.includes('country'));
          const country = countryObj.length ? countryObj[0].long_name : null;
          const stateObj = results[0].address_components.filter((address) => address.types.includes('administrative_area_level_1'));
          const state = stateObj.length ? stateObj[0].long_name : null;
          const cityObj = results[0].address_components.filter((address) => address.types.includes('locality'));
          const city = cityObj.length ? cityObj[0].long_name : null;
          const postcodeObj = results[0].address_components.filter((address) => address.types.includes('postal_code'));
          const postcode = postcodeObj.length ? postcodeObj[0].long_name : null;
          const venue = results[0].formatted_address;
          const latitude = results[0].geometry.location.lat();
          const longitude = results[0].geometry.location.lng();
          setAddressError({
            address: !venue,
            city: !city,
            country: !country,
            state: !state,
            postcode: !postcode,
            lat: !latitude,
            long: !longitude
          });

          setAddressData({
            address: venue,
            city,
            country,
            state,
            postcode,
            lat: latitude,
            long: longitude,
          });

        })
        .catch(error => console.error(error));
    }
    // eslint-disable-next-line
  }, [apiPlaceData]);


  return (
    <Page title="Edit Organization">
      <Container>
        <SimpleBackdrop open={spinner} />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Organization
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <CardProfile setProfileImg={setProfileImg} src={organizerData?.organizerLogo} loading={spinner} />
            <div className="card">
              <h3>Sport Events</h3>
              <FormGroup>
                <FormControlLabel control={<Checkbox color='warning' checked={checkBoxData.league} onChange={() => setCheckBoxData({ ...checkBoxData, league: !checkBoxData.league })} />} label="League" />
                <FormControlLabel control={<Checkbox color='warning' checked={checkBoxData.pickup} onChange={() => setCheckBoxData({ ...checkBoxData, pickup: !checkBoxData.pickup })} />} label="Pick Up" />
                <FormControlLabel control={<Checkbox color='warning' checked={checkBoxData.tournament} onChange={() => setCheckBoxData({ ...checkBoxData, tournament: !checkBoxData.tournament })} />} label="Tournament" />
              </FormGroup>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Card style={{ minHeight: '500px', padding: '20px' }}>
              <Stack spacing={3} style={{ width: '90%' }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField color="warning" label="Name * " value={organizerData?.name || ''} style={{ width: '100%' }} onChange={(e) => handleChange(e, "name")}
                    error={errors.name}
                    helperText={errors.name && MESSAGES.FULLNAME_REQUIRED}
                  />
                  <TextField color="warning" disabled label="Email * " value={organizerData?.email || ''} style={{ width: '100%' }} onChange={(e) => handleChange(e, "email")}
                    error={errors.email}
                    helperText={errors.email && (!organizerData.email ? MESSAGES.EMAIL_REQUIRED : MESSAGES.INVALID_EMAIL)}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField color="warning" label="Phone * " type="number" style={{ width: '100%' }} onChange={(e) => handleChange(e, "phone")}
                    error={errors.phone}
                    inputProps={{ maxLength: 10 }}
                    value={organizerData.phone !== '0' ? organizerData.phone : ''}
                    onInput={(e) => {
                      e.target.value =
                        e.target.value === '0'
                          ? ''
                          : Number(e.target.value).toString().slice(0, 10);
                    }}
                    helperText={errors.phone && (!organizerData.phone ? MESSAGES.PHONE_REQUIRED : MESSAGES.INVALID_PHONE)}
                  />
                  <TextField color="warning" label="Website * " value={organizerData?.website || ''} style={{ width: '100%' }} onChange={(e) => handleChange(e, "website")}
                    error={errors.website}
                    helperText={errors.website && MESSAGES.WEBSITE_REQUIRED}
                  />
                </Stack>

                {/* <TextField color="warning" label="Address Line 1 * " value={organizerData?.addressLine1 || ''} style={{ width: '100%' }} onChange={(e) => handleChange(e, "addressLine1")}
                  error={errors.addressLine1}
                  helperText={errors.addressLine1 && MESSAGES.ADDRESS_LINE1_REQUIRED}
                /> */}
                {/* <TextField color="warning" label="Address Line 2" defaultValue={organizerData?.address2 || ''} style={{ width: '100%' }} onChange={(e) => handleChange(e, "addressLine2")} /> */}

                <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                  <div style={{ width: '100%' }}>
                    <GooglePlacesAutocomplete
                      className="google_autocomplete"
                      apiKey={process.env.REACT_APP_GCP_KEY}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ['us']
                        }
                      }}
                      selectProps={{
                        placeholder: "Search location...",
                        isClearable: "true",
                        styles: googleAutocompleteStyles,
                        apiPlaceData,
                        onChange: setApiPlaceData
                      }}
                    />
                    <FormHelperText>Select location</FormHelperText>
                  </div>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    color="warning"
                    label="Location * "
                    value={addressData?.address || organizerData?.addressLine1 || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => handleChangeAddress(e, 'address')}
                    error={addressError.address}
                    helperText={addressError.address && MESSAGES.VENUE_REQUIRED}
                  />
                  <TextField
                    color="warning"
                    label="City * "
                    value={addressData?.city || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => handleChangeAddress(e, 'city')}
                    error={addressError.city}
                    helperText={addressError.city && MESSAGES.CITY_REQUIRED}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    color="warning"
                    label="State * "
                    value={addressData?.state || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => handleChangeAddress(e, 'state')}
                    error={addressError.state}
                    helperText={addressError.state && MESSAGES.STATE_REQUIRED}
                  />
                  <TextField
                    color="warning"
                    label="Zip code * "
                    value={addressData?.postcode || ''}
                    style={{ width: '100%' }}
                    inputProps={{ maxLength: 6 }}
                    onInput={(e) => {
                      e.target.value = (e.target.value).toString().slice(0, 6);
                    }}
                    onChange={(e) => handleChangeAddress(e, 'postcode')}
                    error={addressError.postcode}
                    type="text"
                    helperText={addressError.postcode && MESSAGES.POSTCODE_REQUIRED}
                  />
                  <TextField
                    color="warning"
                    label="Country * "
                    value={addressData?.country || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => handleChangeAddress(e, 'country')}
                    error={addressError.country}
                    helperText={addressError.country && MESSAGES.COUNTRY_REQUIRED}
                  />
                </Stack>

                <TextField multiline color="warning" label="Description * " inputProps={{ maxLength: 999 }} value={organizerData?.description || ''} style={{ width: '100%' }} onChange={(e) => setOrganizerData({ ...organizerData, description: e.target.value })} />
              </Stack>
            </Card>
          </Grid>
        </Grid>
        <SliderUploadFiles setSliderImgState={setSliderImg} sliderImgState={sliderImg} preloadedImgs={preloadedSliderImg} setPreloadedSliderImg={setPreloadedSliderImg} setDeletedSliderIndex={setDeletedSliderIndex} deletedSliderIndex={deletedSliderIndex} />
        <div className="save_button_div">
          <LoadingButton
            size="large"
            type="button"
            onClick={onSubmit}
            variant="contained"
            color="warning"
            style={{ color: 'white', width: '50%' }}
            loading={spinner}
          >
            Save
          </LoadingButton>
        </div>
      </Container>
    </Page>
  );
};
