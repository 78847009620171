export const isValidEmail = (email) => {
    const format =
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return format.test(email);
};

export const isStrongPassword = (text) => {
    try {
        const fieldVal = text.toString().trim();
        const format =  /^(?=.*\d)(?=.*[!@#$%.^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return format.test(fieldVal);
    } catch (error) {
        console.log(error);
    }
    return false;
};

export const passwordRegex =  /^(?=.*\d)(?=.*[!@#$%.^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const isPhoneNumber = (text) => {
    try {
        const fieldVal = text.toString();
        const format = /^[1-9]{1}[0-9]{9}$/;
        return format.test(fieldVal);
    } catch (err) {
        console.log(err);
    }
    return false;
}

export const statusOfOrganizer = (verified, deactivated) => {
    if (deactivated)
        return {
            title: 'Deactivated',
            color: 'error'
        }
    if (verified)
        return {
            title: 'Verified',
            color: 'success'
        }
    return {
        title: 'Not Verified',
        color: 'warning'
    }
}


export const verifyFileExtension = (file) => {
    const arrOfExtensions = [".png", ".jpg", ".jpeg", ".webp"];
    return arrOfExtensions.find(extension => file.includes(extension));
}

export const fileExtensions = {
    onlyImage: "image/png, image/jpg, image/jpeg, image/webp",
    imageVideo: "image/png, image/jpg, image/jpeg, image/webp, video/*"
}