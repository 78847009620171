import { createSlice } from '@reduxjs/toolkit'

export const dataManager = createSlice({
  name: 'dataManager',
  initialState: {
    organizers: [],
    events: [],
    venues: [],
    broadcasts: [],
    organizerProfile: {},
    adminsDashboard: ''
  },
  reducers: {
    insertOrganizer: (state, actions) => {
      state.organizers.push(actions.payload);
      return state;
    },
    insertEvents: (state, actions) => {
      state.events.push(actions.payload);
      return state;
    },
    insertVenues: (state, actions) => {
      state.venues.push(actions.payload);
      return state;
    },
    resetVenues: (state, actions) => {
      state.venues = [];
      state.venues.push(actions.payload);
      return state;
    },
    resetEvents: (state, actions) => {
      state.events = [];
      state.events.push(actions.payload);
      return state;
    },
    insertBroadcasts: (state, actions) => {
      state.broadcasts = (actions.payload);
      return state;
    },
    resetOrganizer: (state, actions) => {
      state.organizers = [];
      state.organizers.push(actions.payload);
      return state;
    },
    insertOrganizerProfile: (state, actions) => {
      state.organizerProfile = actions.payload;
      return state;
    },
    updateAdminDashboard: (state, actions) => {
      state.adminsDashboard = actions.payload;
      return state;
    }
  }
});


export const { insertOrganizer, insertOrganizerProfile, resetOrganizer, updateAdminDashboard, insertEvents, insertBroadcasts, insertVenues, resetEvents, resetVenues } = dataManager.actions;

export default dataManager.reducer;