import { debounce } from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import swal from 'sweetalert';
import moment from 'moment';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import USERLIST from '../_mock/user';
import { useStyles } from '../Styles/components';
import { fetchEventsList } from '../Services/Events.services';
import { abbreviate, capitalizeFirstLetter } from '../utils/abbreviators';
import { localStorageEnums } from '../constants/LocalStorageEnums';
import { insertEvents, resetEvents } from '../store/features/Data';
import EventMoreMenu from '../sections/@dashboard/events/EventsMoreMenu';
import { ROLES } from '../constants/Roles';
import { DATE_FORMAT, DATE_FORMAT_WITH_TIME } from '../constants/DateFormat';


const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'start', label: 'Start Date', alignRight: false },
    { id: 'end', label: 'End Date', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];


const TABLE_HEAD_ADMIN = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'start', label: 'Start Date', alignRight: false },
    { id: 'end', label: 'End Date', alignRight: false },
    { id: 'org', label: 'Organizer Name', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];


export default function EventList() {
    const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));
    const isAdmin = userData?.role === ROLES.ADMIN;
    const { id } = userData;
    const dispatch = useDispatch();


    const [count, setCount] = useState(0);


    const events = useSelector((state) => state.dataManager.events);


    const classes = useStyles();


    const [page, setPage] = useState(0);


    const [order, setOrder] = useState('asc');


    const [selected, setSelected] = useState([]);


    const [orderBy, setOrderBy] = useState('name');


    const [filterName, setFilterName] = useState('');


    const [rowsPerPage, setRowsPerPage] = useState(10);


    const [eventList, setEventsList] = useState([]);


    const [spinner, setSpinner] = useState(false);


    useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                const response = await fetchEventsList(page, "", id);
                if (response.status === 200) {
                    const data = await response.data;
                    setEventsList(data?.data?.data || []);
                    dispatch(resetEvents(data?.data || []));
                    setCount(data?.data?.count || 0);
                }
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        fetchList();
        // eslint-disable-next-line
    }, [id]);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const fetchListBySearch = async (page, search) => {
        try {
            setSpinner(true);
            setPage(0);
            dispatch(resetEvents([]));
            const response = await fetchEventsList(0, search, id);
            if (response.status === 200) {
                const data = await response.data;
                setEventsList(data?.data?.data || []);
                dispatch(resetEvents(data?.data || []));
                setCount(data?.data?.count);
            }
            setSpinner(false);
        } catch (err) {
            setSpinner(false);
            swal('Error', err.response?.data?.message || "", 'error');
        }
    };


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
        if (events[newPage] && filterName === "") {
            setEventsList(events[newPage]?.data || []);
            setCount(events[newPage]?.count || 0);
        } else {
            try {
                setSpinner(true);
                const response = await fetchEventsList(newPage, filterName, id);
                if (response.status === 200) {
                    const data = await response.data;
                    setEventsList(data?.data?.data);
                    setCount(data?.data?.count);
                    dispatch(insertEvents(data?.data || []));
                }
                setSpinner(false);
            } catch (err) {
                swal('Error', err.response?.data?.message, 'error');
            }
        }
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const changeHandler = (event) => {
        setFilterName(event.target.value);
        setEventsList([]);
        fetchListBySearch(page, event.target.value);
    };


    // eslint-disable-next-line
    const debouncedChangeHandler = useMemo(() => {
        return debounce(changeHandler, 600);
        // eslint-disable-next-line
    }, []);




    const isUserNotFound = eventList?.length === 0;


    return (
        <Page title="Events" goBack={false}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Events
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/dashboard/create-event"
                        startIcon={<Icon icon='carbon:event' />}
                    >
                        Create Event
                    </Button>
                </Stack>
                <Card>
                    <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={debouncedChangeHandler}
                        title="Events"
                    />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={isAdmin ? TABLE_HEAD_ADMIN : TABLE_HEAD}
                                    rowCount={USERLIST.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {eventList?.map((row) => {
                                        const { id, name, eventImage, type, eventVenue, organizer, endTimeNull } = row;
                                        const isItemSelected = selected.indexOf(name) !== -1;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell component="th" scope="row" padding="2px" title={name}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Avatar className={classes.avatar} alt={name} src={eventImage.length > 0 ? `${process.env.REACT_APP_CLOUDFRONT}/${eventImage[0].src}` : null} />
                                                        <Typography variant="subtitle2" noWrap title={name}>
                                                            {abbreviate(name, 30)}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">{capitalizeFirstLetter(type) || 'Not added'}</TableCell>
                                                <TableCell align="left">{moment(eventVenue[0]?.startDate).format(DATE_FORMAT_WITH_TIME)}</TableCell>
                                                <TableCell align="left">{eventVenue[0]?.endDate ? moment(eventVenue[0]?.endDate).format(endTimeNull ? DATE_FORMAT : DATE_FORMAT_WITH_TIME) : "--"}</TableCell>
                                                {isAdmin && <TableCell align="left" title={organizer?.name}>
                                                    {abbreviate(organizer?.name, 25)}
                                                </TableCell>}
                                                <TableCell align="left">
                                                    <EventMoreMenu id={row?.id} fetchList={() => fetchListBySearch(0, filterName)} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>


                                {spinner && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <div className={classes.fullWidth}>
                                                    <CircularProgress className={classes.textAlign} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                                {!spinner && isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound title="No Events Found." />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>


                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
