import PropTypes from 'prop-types';
import React, { useState } from 'react'
import swal from 'sweetalert';
import Button from '@mui/material/Button';
import { CircularProgress, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { useStyles } from '../../Styles/components';
import { AuthResetPasswordServices } from '../../Services/Auth.services';
import { MESSAGES } from '../../constants/ValidationMsgs';
import { isStrongPassword } from '../../utils/validators';

export const ResetPassword = (props) => {
  const classes = useStyles();

  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
    showPassword2: false,
    showPassword3: false
  });
  const [errors, setErrors] = useState({
    password: false,
    confirmPassword: false
  });

  const [spinner, setSpinner] = useState(false);


  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value.trim() });
    if (prop === 'password') {
      setErrors({
        ...errors,
        password: !isStrongPassword(values.password)
      });
    }
    if (prop === 'confirmPassword') {

      setErrors({
        ...errors,
        confirmPassword: values.password !== event.target.value
      });
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      values.password === values.confirmPassword &&
      isStrongPassword(values.password)
    ) {
      setSpinner(true);
      const response = await AuthResetPasswordServices(props?.email, props?.otp, values);
      if (response.status === 201) {
        swal('Password reset successfully.', '', 'success');
        setValues({
          ...values,
          password: '',
          confirmPassword: ''
        });
        props?.handleClose();
      }
    }
    if (values.confirmPassword !== values.password) {
      setErrors({ ...errors, confirmPassword: true });
    }
    else {
      setErrors({ ...errors, password: !isStrongPassword(values.password) });
    }
    setSpinner(false);
  };
  return (
    <div className='login_div'>
      <div className={classes.assignmentGrid}>
        <h1>Reset your password</h1>
        <FormControl className={classes.fullWidthTextField} color="warning" error={errors.password} variant="outlined">
          <InputLabel
            htmlFor="outlined-adornment-password"
          >
            New Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword2 ? 'text' : 'password'}
            value={values.password}
            className={classes.fullWidth}
            onChange={handleChange('password')}
            label="New Password"
            autoComplete="off"
            error={errors.password}
          />
          {errors.password && (
            <FormHelperText error id="accountId-error">
              {values.password.length === 0
                ? MESSAGES.PASSWORDREGQUIRED
                : MESSAGES.INVALIDPASSWORD}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl className={classes.fullWidthTextField} color="warning" error={errors.confirmPassword} variant="outlined">
          <InputLabel
            htmlFor="outlined-adornment-confirm-password"
          >
            Confirm Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-confirm-password"
            type={values.showPassword3 ? 'text' : 'password'}
            value={values.confirmPassword}
            className={classes.fullWidth}
            onChange={handleChange('confirmPassword')}
            label="Confirm Password"
            autoComplete="off"
            error={errors.confirmPassword}
          />
          {errors.confirmPassword && (
            <FormHelperText error id="accountId-error">
              {values.confirmPassword.length === 0
                ? MESSAGES.CONFIRMPASSWORDREQUIRED
                : MESSAGES.INVALIDCONFIRMPASSWORD}
            </FormHelperText>
          )}
        </FormControl>
        {spinner ? <CircularProgress /> : <Button onClick={handleSubmit} className={classes.buttonMargin} variant='contained'>Reset Password</Button>}
      </div>
    </div>
  )
}

ResetPassword.propTypes = {
  handleClose: PropTypes.any,
  email: PropTypes.any,
  otp: PropTypes.any
};