import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import SimpleBackdrop from '../components/Backdrop';
import "../Styles/Slider.css";
import "../Styles/BroadcastPage.css";
import { MESSAGES } from '../constants/ValidationMsgs';
import { fetchOrganizersList, pushNotification } from '../Services/Admin.services';

export const CreateBroadcastPage = () => {

    const [spinner, setSpinner] = useState(false);
    const [organizerList, setOrganizerList] = useState([]);

    const [broadcastData, setBroadcastData] = useState({
        title: '',
        description: '',
        organizer_id: ''
    });

    const [broadcastError, setBroadcastError] = useState({
        title: false,
        description: false
    });

    useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                const response = await fetchOrganizersList();
                if (response.status === 200) {
                    const data = await response.data;
                    setOrganizerList(data.data.data);
                }
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        fetchList();
        // eslint-disable-next-line
    }, []);

    const handleChange = (e, prop) => {
        setBroadcastData({ ...broadcastData, [prop]: e.target.value });
        setBroadcastError({
            ...broadcastError,
            [prop]: !e.target.value
        })
    }

    const onSubmit = async () => {
        try {
            if (broadcastData.title?.trim() && broadcastData.description?.trim()) {
                setSpinner(true);
                const response = await pushNotification(broadcastData, "POST");
                if (response.status === 201) {
                    setSpinner(false);
                    swal("Success", response?.data?.message, "success").then(() => { window.location.href = "/dashboard/broadcast-notification" })
                }
            }
            else {
                setBroadcastError({
                    title: !broadcastData.title?.trim(),
                    description: !broadcastData.description?.trim()
                })
            }
        } catch (err) {
            setSpinner(false);
            swal("Success", err?.response?.data?.message, "success");
        }
    }



    return (
        <Page title="Create Broadcast">
            <Container>
                <SimpleBackdrop open={spinner} />
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Create Broadcast
                    </Typography>
                </Stack>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Card style={{ minHeight: '300px', padding: '20px' }}>
                            <Stack spacing={3} style={{ width: '100%' }}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        color="primary"
                                        label="Broadcast Title * "
                                        value={broadcastData?.title || ''}
                                        style={{ width: '100%' }}
                                        onChange={(e) => handleChange(e, 'title')}
                                        error={broadcastError.title}
                                        helperText={broadcastError.title && MESSAGES.BROADCAST_TITLE_REQUIRED}
                                    />
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                                    <TextField color="primary"
                                        label="Broadcast Description * "
                                        multiline
                                        value={broadcastData?.description || ''}
                                        style={{ width: '100%' }}
                                        inputProps={{ maxLength: 999 }}
                                        onChange={(e) => handleChange(e, 'description')}
                                        error={broadcastError.description}
                                        helperText={broadcastError.description && MESSAGES.BROADCAST_DESC_REQUIRED} />
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label">Select an organizer</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={broadcastData?.organizer_id || ""}
                                            label="Select an organizer"
                                            onChange={(e) => handleChange(e, 'organizer_id')}
                                        >
                                            {organizerList?.map(organizer => <MenuItem key={organizer?.user?.id} value={organizer?.user?.id}>{organizer.name}</MenuItem>)}
                                        </Select>
                                        <FormHelperText>{MESSAGES.BROADCST_ORGANIZER_HELPERTEXT}</FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
                <div className="save_button_div">
                    <LoadingButton
                        size="large"
                        type="button"
                        onClick={onSubmit}
                        variant="contained"
                        color="warning"
                        style={{ color: 'white', width: '50%' }}
                        loading={spinner}

                    >
                        Broadcast
                    </LoadingButton>
                </div>
            </Container>
        </Page>
    );
};
