import PropTypes from 'prop-types';
import React from 'react'
import "../Styles/SliderUpload.css";
import { SliderUpload } from './SliderUpload';

export const SliderUploadFiles = (props) => 
    <div className='slider_div'>
    <SliderUpload setSliderImgState={props.setSliderImgState} sliderImgState={props.sliderImgState} preloadedImgs={props?.preloadedImgs} setPreloadedSliderImg={props?.setPreloadedSliderImg} setDeletedSliderIndex={props?.setDeletedSliderIndex} deletedSliderIndex={props?.deletedSliderIndex}/>
    </div>

SliderUploadFiles.propTypes = {
  setSliderImgState: PropTypes.any,
  sliderImgState: PropTypes.any,
  preloadedImgs: PropTypes.any,
  setPreloadedSliderImg : PropTypes.any,
  setDeletedSliderIndex : PropTypes.any,
  deletedSliderIndex : PropTypes.any
};