import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

export const DateRangerFilter = (props) =>  <DateRange
        editableDateInputs="true"
        onChange={(item) => { props?.setValue([item.selection]); props?.setApply(true);}}
        moveRangeOnFirstSelection="false"
        ranges={props?.value}
    />

DateRangerFilter.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.any,
    setApply: PropTypes.any
};