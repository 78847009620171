import { useRef, useState } from 'react';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import Iconify from '../../../components/Iconify';
import { deleteEventService } from '../../../Services/Events.services';

EventMoreMenu.propTypes = {
    id: PropTypes.number,
    fetchList: PropTypes.any
};

export default function EventMoreMenu({ id, fetchList }) {
    const navigate = useNavigate();
    const ref = useRef(null);

    const [spinner, setSpinner] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const deleteEvent = async (id) => {
        setSpinner(true);
        try {
            const response = await deleteEventService(id);
            if (response.status === 200) {
                setSpinner(false);
                setIsOpen(false);
                const data = await response.data;
                swal("Success", data?.message, "success").then(() => {
                    fetchList();
                });
            }
        } catch (err) {
            setSpinner(false);
            swal("Error", err.response?.data?.message || "", "error");
        }
    }

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
            </IconButton>
            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' },
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >

                <MenuItem onClick={() => navigate(`/dashboard/event/edit/${id}`)} sx={{ color: 'text.secondary' }}>
                    <ListItemIcon>
                        <Iconify icon="bxs:edit" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                <MenuItem onClick={() => navigate(`/dashboard/event/duplicate/${id}`)} sx={{ color: 'text.secondary' }}>
                    <ListItemIcon>
                        <Iconify icon="bx:duplicate" width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="Duplicate Event" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
                <MenuItem onClick={() => deleteEvent(id)} sx={{ color: 'text.secondary' }}>
                    {spinner ? <CircularProgress color="warning" /> : <> <ListItemIcon>
                        <Iconify icon="fluent:delete-24-regular" width={24} height={24} />
                    </ListItemIcon>
                        <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} /> </>}
                </MenuItem>
            </Menu>
        </>
    );
}
