import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import LeagueOrganizer from './pages/LeagueOrganizer';
import { localStorageEnums } from './constants/LocalStorageEnums';
import { OganizerProfile } from './pages/OganizerProfile';
import { CreateEvent } from './pages/CreateEvent';
import { ROLES } from './constants/Roles';
import EventList from './pages/EventList';
import { EditEvent } from './pages/EditEvent';
import { VideoPage } from './pages/Video';
import { CreateBroadcastPage } from './pages/CreateBroadcastNotification';
import { EditBroadcastPage } from './pages/EditBroadcastNotification';
import { BroadcastPage } from './pages/BroadcastList';
import { CreateVenue } from './pages/CreateVenue';
import VenueList from './pages/VenueList';
import { EditVenue } from './pages/EditVenue';
import GraphListById from './pages/GraphListById';

export default function Router() {
  const userData = JSON.parse(window.localStorage.getItem(localStorageEnums?.USERDATA));
  const authenticated = window.localStorage.getItem(localStorageEnums.TOKEN);
  return useRoutes([
    {
      path: '/dashboard',
      element: authenticated ? (
        <DashboardLayout />
      ) : (
        <Navigate to="/login" replace />
      )
      ,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'create-event', element: <CreateEvent /> },
        { path: 'create-venue', element: <CreateVenue /> },
        { path: 'edit-venue/:id', element: <EditVenue /> },
        { path: 'event/:action/:id', element: <EditEvent /> },
        { path: 'events', element: < EventList /> },
        { path: 'venues', element: < VenueList /> },
        { path: 'graph/:id/:title', element: < GraphListById /> },
        { path: 'video', element: ROLES?.ADMIN === userData?.role ? < VideoPage /> : <Navigate to="*" /> },
        { path: 'broadcast-notification', element: ROLES?.ADMIN === userData?.role ? <BroadcastPage /> : <Navigate to="*" /> },
        { path: 'create-broadcast-notification', element: ROLES?.ADMIN === userData?.role ? <CreateBroadcastPage /> : <Navigate to="*" /> },
        { path: 'edit-broadcast-notification/:id', element: ROLES?.ADMIN === userData?.role ? <EditBroadcastPage /> : <Navigate to="*" /> },
        { path: 'organizers', element: ROLES?.ADMIN === userData?.role ? <LeagueOrganizer /> : <Navigate to="*" /> },
        { path: 'my-profile', element: ROLES?.ORGANIZER === userData?.role ? <OganizerProfile /> : <Navigate to="*" /> }
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: !authenticated ? <Login /> : <Navigate to="/dashboard/app" /> },
        { path: 'register', element: !authenticated ? <Register /> : <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
