import React, { useState } from 'react';
import swal from 'sweetalert';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CellTowerIcon from '@mui/icons-material/CellTower';
import Page from '../components/Page';
import SimpleBackdrop from '../components/Backdrop';
import "../Styles/Slider.css";
import "../Styles/BroadcastPage.css";
import { MESSAGES } from '../constants/ValidationMsgs';
import { pushNotification, getNotificationById } from '../Services/Admin.services';

export const EditBroadcastPage = () => {

    const { id } = useParams();

    const [spinner, setSpinner] = useState(false);

    const [broadcastData, setBroadcastData] = useState({
        title: '',
        description: ''
    });

    const [broadcastError, setBroadcastError] = useState({
        title: false,
        description: false
    });

    const handleChange = (e, prop) => {
        setBroadcastData({ ...broadcastData, [prop]: e.target.value });
        setBroadcastError({
            ...broadcastError,
            [prop]: !e.target.value
        })
    }

    React.useEffect(() => {
        const fetchNotification = async () => {
            const response = await getNotificationById(id);
            const { title = "", description = "" } = response?.data?.data;
            setBroadcastData({
                title,
                description
            });
        }
        fetchNotification();
        // eslint-disable-next-line
    }, []);

    const onSubmit = async () => {
        try {
            if (broadcastData.title && broadcastData.description) {
                setSpinner(true);
                const response = await pushNotification(broadcastData, "PATCH");
                if (response.status === 201) {
                    setSpinner(false);
                    swal("Success", response?.data?.message, "success");
                }
            }
            else {
                setBroadcastError({
                    title: !broadcastData.title,
                    description: !broadcastData.description
                })
            }
        } catch (err) {
            setSpinner(false);
            swal("Success", err?.response?.data?.message, "success");
        }
    }

    return (
        <Page title="Edit Broadcast">
            <Container>
                <SimpleBackdrop open={spinner} />
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Broadcast
                    </Typography>
                </Stack>
                <Grid container>
                    <div className='broadcast_div_main'>
                        <Stack spacing={3} style={{ width: '100%' }}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <TextField
                                    color="primary"
                                    label="Broadcast Title * "
                                    value={broadcastData?.title || ''}
                                    style={{ width: '100%' }}
                                    onChange={(e) => handleChange(e, 'title')}
                                    error={broadcastError.title}
                                    helperText={broadcastError.title && MESSAGES.BROADCAST_TITLE_REQUIRED}
                                />
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                                <textarea onChange={(e) => handleChange(e, 'description')} placeholder='Broadcast Description * ' className={broadcastError.description ? 'broadcast_description_error' : 'broadcast_description'} value={broadcastData?.description} />
                                {broadcastError.description && <FormHelperText className='boradcast_desc_error_text'>
                                    {MESSAGES.BROADCAST_DESC_REQUIRED}
                                </FormHelperText>}
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <LoadingButton
                                    size="large"
                                    type="button"
                                    onClick={onSubmit}
                                    variant="contained"
                                    color="warning"
                                    style={{ color: 'white', margin: 'auto' }}
                                    loading={spinner}

                                >
                                    <CellTowerIcon />
                                    Edit
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    </div>
                </Grid>
            </Container>
        </Page>
    );
};
