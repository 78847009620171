import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import swal from 'sweetalert';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AuthRegisterServices } from '../../../Services/Auth.services';
import { MESSAGES } from '../../../constants/ValidationMsgs';
import { isPhoneNumber, isStrongPassword, isValidEmail } from '../../../utils/validators';

export default function RegisterForm() {
  const navigate = useNavigate();

  const [coordinates, setCoordinates] = React.useState({
    lat: '',
    lng: '',
  });

  const [emailErrMsg, setEmailErrMsg] = React.useState(MESSAGES.EMAIL_REQUIRED);

  const [phoneErrMsg, setPhoneErrMsg] = React.useState(MESSAGES.PHONE_REQUIRED);

  const [showPassword, setShowPassword] = useState(false);

  const [showCPassword, setShowCPassword] = useState(false);

  const [spinner, setSpinner] = useState(false);

  const [formBody, setFormBody] = React.useState({
    name: '',
    phone: '',
    url: '',
    desc: '',
    email: '',
    password: '',
    cpassword: ''
  });

  const [errors, setErrors] = React.useState({
    name: false,
    phone: false,
    url: false,
    desc: false,
    email: false,
    password: false,
    cpassword: false,
  });

  React.useEffect(() => {
    window.navigator.geolocation.getCurrentPosition(async ({ coords }) => {
      const { latitude, longitude } = coords;
      setCoordinates({
        lat: latitude,
        lng: longitude,
      });
    });
  }, []);

  const handleChange = async (e, param) => {
    if (param === 'phone') {
      setFormBody({
        ...formBody,
        phone: e.target.value.trim(),
      });
      setPhoneErrMsg(!e.target.value ? MESSAGES.PHONE_REQUIRED : MESSAGES.INVALID_PHONE);
      setErrors({
        ...errors,
        phone: !isPhoneNumber(e.target.value),
      });
    }
    else if (param === 'email') {
      setFormBody({
        ...formBody,
        email: e.target.value.trim(),
      });
      setEmailErrMsg(!e.target.value ? MESSAGES.EMAIL_REQUIRED : MESSAGES.INVALID_EMAIL);
      setErrors({
        ...errors,
        email: !isValidEmail(e.target.value),
      });
    } else if (param === 'password') {
      setFormBody({
        ...formBody,
        password: e.target.value.trim(),
      });

      setErrors({
        ...errors,
        password: !isStrongPassword(e.target.value),
      });
    } else if (param === 'cpassword') {
      setFormBody({
        ...formBody,
        cpassword: e.target.value.trim(),
      });

      setErrors({
        ...errors,
        cpassword: e.target.value !== (formBody.password),
      });
    }

    else if (param === 'url') {
      setFormBody({
        ...formBody,
        url: e.target.value.trim(),
      });

      setErrors({
        ...errors,
        url: !(e.target.value),
      });
    }
    else {
      setFormBody({
        ...formBody,
        [param]: e.target.value.trim().length > 0 ? e.target.value : e.target.value.trim(),
      });
      setErrors({
        ...errors,
        [param]: !e.target.value.trim(),
      });
    }
  };

  const onSubmit = async () => {
    try {
      if (
        formBody.name.trim() &&
        isValidEmail(formBody.email) &&
        isStrongPassword(formBody.password) &&
        isPhoneNumber(formBody.phone) &&
        formBody.desc.trim() &&
        formBody.url.trim() &&
        (formBody.cpassword === formBody.password)
      ) {
        setSpinner(true);
        const body = {
          fullName: formBody.name,
          phone: formBody.phone,
          url: formBody.url,
          desc: formBody.desc,
          password: formBody.password,
          email: formBody.email,
        };
        if (coordinates.lat && coordinates.lng) {
          body.loc_lat = coordinates.lat;
          body.loc_long = coordinates.lng;
        };
        const response = await AuthRegisterServices(body);
        const data = await response.data;
        setSpinner(false);
        swal('Success', data?.message, 'success').then(() => {
          navigate('/login', { replace: true });
        });
      }
      else {
        setErrors({
          name: !formBody.name,
          phone: !formBody.phone,
          url: !formBody.url,
          desc: !formBody.desc,
          password: !isStrongPassword(formBody.password),
          email: !isValidEmail(formBody.email)
        })
      }
    } catch (err) {
      setSpinner(false);
      swal('Error', err?.response?.data?.message, 'error');
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };

  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Stack spacing={3}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          color="warning"
          id="outlined-basic"
          style={{ width: '100%' }}
          label="Entity Name"
          variant="outlined"
          value={formBody.name}
          error={errors.name}
          helperText={errors.name ? MESSAGES.ENTITY_NAME_REQUIRED : null}
          autoComplete="off"
          onChange={(e) => handleChange(e, 'name')}
        />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          color="warning"
          id="outlined-basic"
          style={{ width: '100%' }}
          label="Email Address"
          type="email"
          variant="outlined"
          value={formBody.email}
          error={errors.email}
          autoComplete="off"
          helperText={errors.email && emailErrMsg}
          onChange={(e) => handleChange(e, 'email')}
        />
        <TextField
          color="warning"
          id="outlined-basic"
          style={{ width: '100%' }}
          label="Entity Phone Number"
          variant="outlined"
          type="number"
          value={formBody.phone !== '0' ? formBody.phone : ''}
          onInput={(e) => {
            e.target.value =
              e.target.value === '0'
                ? ''
                : Number(e.target.value).toString().slice(0, 10);
          }}
          error={errors.phone}
          helperText={errors.phone && phoneErrMsg}
          autoComplete="off"
          onChange={(e) => handleChange(e, 'phone')}
        />
      </Stack>
      <TextField
        color="warning"
        id="outlined-basic"
        style={{ width: '100%' }}
        label="Entity Website"
        variant="outlined"
        type="text"
        value={formBody.url}
        error={errors.url}
        helperText={errors.url ? MESSAGES.URL_REQUIRED : null}
        autoComplete="off"
        onChange={(e) => handleChange(e, 'url')}
      />
      <TextField
        color="warning"
        id="outlined-basic"
        style={{ width: '100%' }}
        label="Entity Description"
        variant="outlined"
        type="text"
        value={formBody.desc}
        inputProps={{ maxLength: 999 }}
        error={errors.desc}
        multiline
        helperText={errors.desc ? MESSAGES.DESC_REQUIRED : null}
        autoComplete="off"
        onChange={(e) => handleChange(e, 'desc')}
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <FormControl color="warning" style={{ width: '100%' }} variant="outlined" error={errors.password}>
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={formBody.password}
            style={{ width: '100%' }}
            onChange={(e) => handleChange(e, 'password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword()}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
          {errors.password && (
            <FormHelperText error id="accountId-error">
              {formBody?.password?.length === 0 ? MESSAGES.PASSWORD_REQUIRED : MESSAGES.INVALID_PASSWORD}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl color="warning" style={{ width: '100%' }} variant="outlined" error={errors.cpassword}>
          <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showCPassword ? 'text' : 'password'}
            value={formBody.cpassword}
            style={{ width: '100%' }}
            onChange={(e) => handleChange(e, 'cpassword')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowCPassword()}
                  onMouseDown={handleMouseDownCPassword}
                  edge="end"
                >
                  {showCPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm Password"
          />
          {errors.cpassword && (
            <FormHelperText error id="accountId-error">
              {formBody?.cpassword?.length === 0 ? MESSAGES.CONFIRM_PASSWORD_REQUIRED : MESSAGES.INVALID_CONFIRM_PASSWORD}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>

      <LoadingButton fullWidth size="large" type="button" variant="contained" onClick={onSubmit} loading={spinner}>
        Register
      </LoadingButton>
    </Stack>
  );
}
