export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export function abbreviate(s, length) {
    return `${s?.slice(0, length)} ${s?.length > length ? '...' : ''}`;
}

export function privacyAbbreviation(s, length) {
    const indexofAt = s.indexOf('@');
    const beginOfAbbreviation = Math.abs(indexofAt - length);
    let stars = "";
    for (let i = beginOfAbbreviation; i < indexofAt; i+=1)
        stars += "*";
    return `${s?.slice(0, beginOfAbbreviation)}${stars}${s?.slice(indexofAt, s.length)}`;
}
