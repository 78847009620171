import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button, Card, FormHelperText, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UploadIcon from '@mui/icons-material/Upload';
import DoneIcon from '@mui/icons-material/Done';
import swal from 'sweetalert';
import Page from '../components/Page';
import SimpleBackdrop from '../components/Backdrop';
import '../Styles/Slider.css';
import '../Styles/VideoPage.css';
import {
  uploadVideoService,
  getVideoService,
  deleteVideoByIdService,
  uploadVideoDataService,
} from '../Services/Admin.services';
import { localStorageEnums } from '../constants/LocalStorageEnums';
import { MESSAGES } from '../constants/ValidationMsgs';
import uploadLogo from '../Images/upload-photo.png';
import { fileExtensions, verifyFileExtension } from '../utils/validators';

export const VideoPage = () => {
  const [spinner, setSpinner] = useState(false);

  const [videoFlag, setVideoFlag] = useState(true);

  const formdata = new FormData();

  const [video, setVideo] = useState('');

  const [videoData, setVideoData] = useState({
    title: '',
    description: '',
  });

  const [videoSrc, setVideoSrc] = useState('');

  const openFileSeletor = () => {
    document.getElementById('file').click();
  };

  const handleChange = (e) => {
    if (!e.target.files[0].type.includes('image') && !e.target.files[0].type.includes('video')) {
      swal('Info', MESSAGES.IMG_VALIDATIONS, 'info');
      return;
    }
    if (!e.target.files[0].type.includes('video') && !verifyFileExtension(e.target?.files[0]?.name?.toLowerCase())) {
      swal('Info', MESSAGES.IMG_VALIDATIONS, 'info');
      return;
    }
    setVideo(e.target.files[0]);
    setVideoFlag(e.target.files[0].type.includes('video'));
    if (e.target.files[0].type.includes('video')) document.getElementById('myVideo2').play();
  };

  const deleteVideoById = async () => {
    try {
      swal({
        title: 'Are you sure?',
        text: MESSAGES.DELETE_VIDEO_MSG_COMFIRMATION,
        icon: 'warning',
        customClass: 'Custom_Cancel',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setSpinner(true);
          const response = await deleteVideoByIdService(videoSrc?.id);
          if (response.status === 200) {
            setSpinner(false);
            swal('Success', response.data.message, 'success').then(() => {
              window.location.reload();
            });
          }
        }
      });
    } catch (err) {
      swal('Error', err.response?.data?.message || "", 'error');
    }
  };

  const uploadVideo = async () => {
    try {
      if (video) {
        setSpinner(true);
        formdata.append('home_screen_video', video);
        const response = await uploadVideoService(formdata, {
          Authorization: `Bearer ${window.localStorage.getItem(localStorageEnums.TOKEN)}`,
        });
        setSpinner(false);
        swal('Success', response.data.message, 'success').then(() => window.location.reload());
      }
    } catch (err) {
      swal('Error', err.response?.data?.message || "", 'error');
    }
  };

  const uploadText = async () => {
    try {
      setSpinner(true);
      formdata.append('title', videoData.title);
      formdata.append('description', videoData.description);
      const response = await uploadVideoDataService(formdata, {
        Authorization: `Bearer ${window.localStorage.getItem(localStorageEnums.TOKEN)}`,
      });
      setSpinner(false);
      swal('Success', response.data.message, 'success').then(() => window.location.reload());
    } catch (err) {
      swal('Error', err.response?.data?.message || "", 'error');
    }
  };

  React.useEffect(() => {
    const fetchVideo = async () => {
      setSpinner(true);
      const response = await getVideoService();
      const data = await response.data;
      const videoArray = data?.data;
      const lastestVideoIndex = videoArray.length - 1;
      setVideoSrc(videoArray[lastestVideoIndex]);
      setVideoData({
        title: videoArray[lastestVideoIndex]?.title || '',
        description: videoArray[lastestVideoIndex]?.description || '',
      });
      setVideoFlag(videoArray[lastestVideoIndex]?.url?.includes('.mp4'));
      setSpinner(false);
    };
    fetchVideo();
  }, []);

  const handleChangeText = (e, prop) => {
    setVideoData({ ...videoData, [prop]: e.target.value });
  };

  React.useEffect(() => {
    if (videoSrc && !video && videoFlag) {
      document.getElementById('myVideo1').play();
    }
  }, [videoSrc, video, videoFlag]);

  const deleteLocalVideo = () => {
    swal({
      title: 'Are you sure?',
      text: MESSAGES.DELETE_VIDEO_MSG_COMFIRMATION,
      icon: 'warning',
      customClass: 'Custom_Cancel',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setVideo('');
      }
    });
  };

  return (
    <Page title="Video/Image" goBack={false}>
      <Container>
        <SimpleBackdrop open={spinner} />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Video/Image
          </Typography>
        </Stack>
        <Grid container>
          {videoFlag && (
            <div className="video_player_div">
              {!video && videoSrc && (
                /* eslint-disable jsx-a11y/media-has-caption */
                <video height={'100%'} style={{ marginBottom: '20px', maxWidth: '1200px' }} muted loop id="myVideo1">
                  <source
                    width="100%"
                    style={{ maxWidth: '1200px' }}
                    src={`${process.env.REACT_APP_CLOUDFRONT}/${videoSrc?.url}`}
                    type="video/mp4"
                  />
                </video>
              )}

              {video /* eslint-disable jsx-a11y/media-has-caption */ && (
                <video height={'100%'} style={{ marginBottom: '20px' }} autoPlay loop id="myVideo2">
                  <source width="100%" src={URL.createObjectURL(video)} type="video/mp4" />
                </video>
              )}
            </div>
          )}
          {!videoFlag && (
            <div className="video_player_div">
              {!video && videoSrc && (
                <img id="myVideo2" src={`${process.env.REACT_APP_CLOUDFRONT}/${videoSrc?.url}`} alt="cover" />
              )}
              {video && <img id="myVideo2" src={URL.createObjectURL(video)} alt="cover" />}
              {!video && !videoSrc && (
                <div className="video_player_div_flex">
                  <img className="default_upload_img" src={uploadLogo} alt="cover" />
                  <p>use below button for upload video/image</p>
                </div>
              )}
            </div>
          )}

          <FormHelperText>{MESSAGES.VIDEO_INSTRUCTIONS}</FormHelperText>
          <div className="video_player_flex_button">
            <input
              type="file"
              style={{ display: 'none' }}
              name="file"
              id="file"
              accept={fileExtensions.imageVideo}
              className="file"
              onChange={(e) => {
                handleChange(e);
                e.target.value = '';
              }}
            />
            {!videoSrc && (
              <Button
                className="video_btn"
                variant="contained"
                color="info"
                onClick={openFileSeletor}
                disabled={spinner}
              >
                {' '}
                <UploadIcon /> Upload
              </Button>
            )}
            {videoSrc && !video && (
              <>
                <Button
                  className="video_btn"
                  variant="contained"
                  color="info"
                  onClick={openFileSeletor}
                  disabled={spinner}
                >
                  {' '}
                  <UploadIcon /> Replace
                </Button>
                <Button
                  className="video_btn"
                  variant="contained"
                  color="error"
                  onClick={deleteVideoById}
                  disabled={spinner}
                >
                  {' '}
                  <DeleteForeverIcon /> Delete
                </Button>
              </>
            )}
            {video && (
              <>
                <Button
                  className="video_btn"
                  variant="contained"
                  color="success"
                  onClick={uploadVideo}
                  disabled={spinner}
                >
                  {' '}
                  <DoneIcon /> Done
                </Button>{' '}
                <Button
                  className="video_btn"
                  variant="contained"
                  color="error"
                  onClick={deleteLocalVideo}
                  disabled={spinner}
                >
                  {' '}
                  <DeleteForeverIcon /> Delete
                </Button>
              </>
            )}
          </div>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Card style={{ minHeight: '200px', padding: '20px' }}>
                <Stack spacing={3} style={{ width: '100%' }}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      color="primary"
                      label="Title "
                      value={videoData?.title || ''}
                      style={{ width: '100%' }}
                      inputProps={{
                        maxLength: 47,
                      }}
                      onChange={(e) => handleChangeText(e, 'title')}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                    <TextField
                      color="primary"
                      label="Description"
                      multiline
                      value={videoData?.description || ''}
                      style={{ width: '100%' }}
                      inputProps={{ maxLength: 200 }}
                      onChange={(e) => handleChangeText(e, 'description')}
                    />
                  </Stack>
                </Stack>
                <br />
                <Button
                  className="video_btn"
                  variant="contained"
                  color="success"
                  onClick={uploadText}
                  disabled={spinner}
                >
                  {' '}
                  <DoneIcon />
                  Update Text
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
