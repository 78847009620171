import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function convert(str) {
  const date = new Date(str);
  const mnth = ("0".concat(date.getMonth() + 1)).slice(-2);
  const day = ("0".concat(date.getDate())).slice(-2);
  return [day, mnth, date.getFullYear()].join("/");
}

export function convertMDY(str) {
  const date = new Date(str);
  const mnth = ("0".concat(date.getMonth() + 1)).slice(-2);
  const day = ("0".concat(date.getDate())).slice(-2);
  return [mnth, day, date.getFullYear()].join("/");
}

export function convertYMD(str) {
  const date = new Date(str);
  const mnth = ("0".concat(date.getMonth() + 1)).slice(-2);
  const day = ("0".concat(date.getDate())).slice(-2);
  return [date.getFullYear(), mnth, day].join("/");
}

export function convertYMDbyHyphen(str) {
  const date = new Date(str);
  const mnth = ("0".concat(date.getMonth() + 1)).slice(-2);
  const day = ("0".concat(date.getDate())).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}



