import { fixedEncodeURIComponent } from "../utils/abbreviators";
import instance from "../Interceptor";
import { headers } from "../utils/headers";

export const fetchListByRole = async (page) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/organizer/list?page=${page + 1}`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchOrganizersList = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/organizer/list`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchListBySearchServices = async (page, search) => {
    const url = fixedEncodeURIComponent(search);
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/organizer/list?page=${page + 1}&search=${url}`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchOrganizersCount = async (page) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/organizer/list?page=${page + 1}`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchUsersCount = async (page) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/user/list?page=${page + 1}&role=user`, {
        method: "GET",
        headers
    });
    return response;
}


export const verifyOrganizerById = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/admin/organization-approve/${id}`, {
        method: "POST",
        headers
    });
    return response;
}

export const deactivateOrganizerById = async (id, value) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/admin/organization-deactivate/${id}`, {
        method: "POST",
        headers,
        data: {
            "is_deactivated": String(value)
        }
    });
    return response;
}

export const uploadVideoService = async (formbody, headerToken) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/home-screen-video`, {
        method: "POST",
        headers: headerToken,
        data: formbody
    });
    return response;
}


export const uploadVideoDataService = async (formbody, headerToken) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/home-screen-video`, {
        method: "PATCH",
        headers: headerToken,
        data: formbody
    });
    return response;
}


export const getVideoService = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/home-screen-video`, {
        method: "GET",
        headers
    });
    return response;
}

export const deleteVideoByIdService = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/home-screen-video/${id}`, {
        method: "DELETE",
        headers
    });
    return response;
}


export const pushNotification = async (data, method) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/notifications`, {
        method,
        headers,
        data: JSON.stringify(data)
    });
    return response;
}


export const getNotificationList = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/notifications`, {
        method: "GET",
        headers
    });
    return response;
}

export const getNotificationById = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/notifications/${id}`, {
        method: "GET",
        headers
    });
    return response;
}

export const deleteNotificationService = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/notifications/${id}`, {
        method: "DELETE",
        headers
    });
    return response;
}

export const getConfigs = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/configs`, {
        method: "GET",
        headers
    });
    return response;
}

