import PropTypes from 'prop-types';
import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from '@mui/material';

const DatePickerExampleDisableDates = (props) => {
    const [openDatePicker, setOpenDatePicker] = React.useState(false);

    const [value, setValue] = React.useState(props?.data);

    const handleChange = (newValue) => {
        setValue(newValue);
        setOpenDatePicker(false);
        props.setData(newValue);
    };
    return <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker label={props?.label}
            inputFormat="MM/dd/yyyy"
            value={value}
            open={openDatePicker}
            onChange={handleChange}
            disablePast
            renderInput={(params) => <TextField {...params}
                error={props?.error}
                helperText={props?.error && props?.helperText}
                onClick={() => setOpenDatePicker(!openDatePicker)}
            />}
        />
    </LocalizationProvider>
};

export default DatePickerExampleDisableDates;

DatePickerExampleDisableDates.propTypes = {
    setData: PropTypes.any,
    data: PropTypes.any,
    label: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.any
};