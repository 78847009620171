import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardHeader } from '@mui/material';
import { fNumber } from '../../../utils/formatNumber';
import { BaseOptionChart } from '../../../components/chart';
import { convertYMDbyHyphen } from '../../../utils/formatTime';


AppConversionRates.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  datePickerRangeState: PropTypes.any
};

export default function AppConversionRates({ title, subheader, chartData, datePickerRangeState, ...other }) {
  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: { horizontal: false, barHeight: '28%', borderRadius: 2 },
    },
    xaxis: {
      categories: chartLabels,
    },
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const end = (convertYMDbyHyphen(datePickerRangeState[0].endDate));
          const start = (convertYMDbyHyphen(datePickerRangeState[0].startDate));
          const url = `/dashboard/graph/${chartData[config.dataPointIndex].sportId}/${chartLabels[config.dataPointIndex]}`;
          window.open(`${window.location.origin}${url}?startDate=${start}&endDate=${end}`, '_blank').focus();
        }
      }
    }
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
