import { sentenceCase } from 'change-case';
import React, { useState, useMemo, useEffect } from 'react';
import debounce from "lodash.debounce";
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import USERLIST from '../_mock/user';
import { useStyles } from '../Styles/components';
import { fetchListByRole, fetchListBySearchServices } from '../Services/Admin.services';
import { insertOrganizer, resetOrganizer } from '../store/features/Data';
import { abbreviate, renderAvatarTitle } from '../utils/abbreviators';
import { statusOfOrganizer } from '../utils/validators';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'website', label: 'Website', alignRight: false },
  { id: 'status', label: 'Verified', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false }
];

export default function LeagueOrganizer() {
  const dispatch = useDispatch();

  const [count, setCount] = useState(0);

  const organizers = useSelector((state) => state.dataManager.organizers);

  const classes = useStyles();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [organizerList, setOrganizerList] = useState([]);

  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    const fetchList = async () => {
      try {
        setSpinner(true);
        const response = await fetchListByRole(page);
        if (response.status === 200) {
          const data = await response.data;
          setOrganizerList(data.data.data);
          dispatch(resetOrganizer(data?.data || []));
          setCount(data?.data?.total);
        }
        setSpinner(false);
      } catch (err) {
        setSpinner(false);
        swal('Error', err.response?.data?.message || "", 'error');
      }
    };
    fetchList();
    // eslint-disable-next-line
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    if (organizers[newPage] && filterName === "") {
      setOrganizerList(organizers[newPage]?.data || []);
      setCount(organizers[newPage]?.total || 0);
    } else {
      try {
        setSpinner(true);
        const response = await fetchListByRole(newPage);
        if (response.status === 200) {
          const data = await response.data;
          setOrganizerList(data.data.data);
          setCount(data?.data?.total);
          dispatch(insertOrganizer(data?.data || []));
        }
        setSpinner(false);
      } catch (err) {
        swal('Error', err.response?.data?.message, 'error');
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchList = async () => {
    window.location.reload();
  };

  const filteredUsers = organizerList;

  const openWebsite = (website) => {
    if (website.includes("http")) {
      window.open(
        website,
        '_blank',
        'noopener,noreferrer'
      )
    } else {
      window.open(
        `https://${website}`,
        '_blank',
        'noopener,noreferrer'
      )
    }
  }

  const fetchListBySearch = async (page, search) => {
    try {
      setSpinner(true);
      setPage(0);
      dispatch(resetOrganizer([]));
      const response = await fetchListBySearchServices(page, search);
      if (response.status === 200) {
        const data = await response.data;
        setOrganizerList(data?.data?.data);
        setCount(data?.data?.total);
        dispatch(resetOrganizer(data?.data || []));
      }
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
      swal('Error', err.response?.data?.message || "", 'error');
    }
  };

  const isUserNotFound = filteredUsers?.length === 0;

  const changeHandler = (event) => {
    setFilterName(event.target.value);
    setOrganizerList([]);
    fetchListBySearch(page, event.target.value);
  };

  // eslint-disable-next-line
  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeHandler, 600);
    // eslint-disable-next-line
  }, []);

  return (
    <Page title="Organizers" goBack={false}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Organizers
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={debouncedChangeHandler}
            title="Organizers"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers?.map((row) => {
                    const { userId, name, phone, email, organizerLogo, isVerified, website, isDeactivated } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={userId}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell component="th" scope="row" padding="2px">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar className={classes.avatar} alt={name} src={organizerLogo ? `${process.env.REACT_APP_CLOUDFRONT}/${organizerLogo}` : null}>{!organizerLogo && renderAvatarTitle(name.toUpperCase(), "", email)}</Avatar>
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{phone || 'Not added'}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell
                          align="left"
                          title={website}
                          onClick={() => openWebsite(website)}
                        >
                          {website ? <p style={{ cursor: 'pointer' }}>{abbreviate(website, 15)}</p> : 'Not added'}
                        </TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={statusOfOrganizer(isVerified, isDeactivated).color}>
                            {sentenceCase(statusOfOrganizer(isVerified, isDeactivated).title)}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          <UserMoreMenu fetchOrganizers={fetchList} id={userId} isVerified={isVerified} isDeactivated={isDeactivated} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {spinner && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <div className={classes.fullWidth}>
                          <CircularProgress className={classes.textAlign} />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {!spinner && isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound title="No Organizers Found." />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
