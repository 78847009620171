import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import {
    Card,
    Table,
    Stack,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    CircularProgress,
    Button,
} from '@mui/material';
import swal from 'sweetalert';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
import { localStorageEnums } from '../constants/LocalStorageEnums';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import USERLIST from '../_mock/user';
import VenueMoreMenu from '../sections/@dashboard/venues/VenueMoreMenu';
import { useStyles } from '../Styles/components';
import { fetchVenueListBySearch } from '../Services/Events.services';
import { abbreviate } from '../utils/abbreviators';
import { insertVenues, resetVenues } from '../store/features/Data';
import { ROLES } from '../constants/Roles';


const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'address', label: 'Address', alignRight: false },
    { id: 'city', label: 'City', alignRight: false },
    { id: 'state', label: 'State', alignRight: false },
    { id: 'country', label: 'Country', alignRight: false },
    { id: 'postcode', label: 'Postcode', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
];

const TABLE_HEAD_ADMIN = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'address', label: 'Address', alignRight: false },
    { id: 'city', label: 'City', alignRight: false },
    { id: 'state', label: 'State', alignRight: false },
    { id: 'country', label: 'Country', alignRight: false },
    { id: 'postcode', label: 'Postcode', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'org', label: 'Organizer Name', alignRight: false },

];

export default function VenueList() {
    const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));
    const isAdmin = userData?.role === ROLES.ADMIN;
    const dispatch = useDispatch();

    const [count, setCount] = useState(0);

    const venues = useSelector((state) => state.dataManager.venues);

    const classes = useStyles();

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [venuesList, setVenuesList] = useState([]);

    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                const response = await fetchVenueListBySearch(filterName, page);
                if (response.status === 200) {
                    const data = await response.data;
                    setVenuesList(data?.data?.venues || []);
                    dispatch(resetVenues(data?.data || []));
                    setCount(data?.data?.count || 0);
                }
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        fetchList();
        // eslint-disable-next-line
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
        if (venues[newPage] && filterName === "") {
            setVenuesList(venues[newPage]?.venues || []);
            setCount(venues[newPage]?.count || 0);
        } else {
            try {
                setSpinner(true);
                const response = await fetchVenueListBySearch(filterName, newPage);
                if (response.status === 200) {
                    const data = await response.data;
                    setVenuesList(data?.data?.venues || []);
                    setCount(data?.data?.count);
                    dispatch(insertVenues(data?.data || []));
                }
                setSpinner(false);
            } catch (err) {
                swal('Error', err.response?.data?.message, 'error');
            }
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchListBySearch = async (search) => {
        try {
            setSpinner(true);
            setPage(0);
            dispatch(resetVenues([]));
            const response = await fetchVenueListBySearch(search, 0);
            if (response.status === 200) {
                const data = await response.data;
                setVenuesList(data?.data?.venues || []);
                dispatch(resetVenues(data?.data || []));
                setCount(data?.data?.count);
            }
            setSpinner(false);
        } catch (err) {
            setSpinner(false);
            swal('Error', err.response?.data?.message || "", 'error');
        }
    };

    const changeHandler = (event) => {
        setFilterName(event.target.value);
        setVenuesList([]);
        fetchListBySearch(event.target.value);
    };

    // eslint-disable-next-line
    const debouncedChangeHandler = useMemo(() => {
        return debounce(changeHandler, 600);
        // eslint-disable-next-line
    }, []);

    const isUserNotFound = venuesList?.length === 0;

    return (
        <Page title="Venues" goBack={false}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Venues
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/dashboard/create-venue"
                        startIcon={<Icon icon='material-symbols:stadium-outline' />}
                    >
                        Create Venue
                    </Button>

                </Stack>

                <Card>
                    <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={debouncedChangeHandler}
                        title="Venues"
                    />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={isAdmin ? TABLE_HEAD_ADMIN : TABLE_HEAD}
                                    rowCount={USERLIST.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {venuesList?.map((row) => {
                                        const { id, name, venueImage, address, city, state, country, postcode, description, organizerVenue } = row;
                                        const isItemSelected = selected.indexOf(name) !== -1;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell component="th" scope="row" padding="2px" title={name}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Avatar className={classes.avatar} alt={name} src={venueImage?.length > 0 ? `${process.env.REACT_APP_CLOUDFRONT}/${venueImage[0].image}` : null} />
                                                        <Typography variant="subtitle2" noWrap>
                                                            {abbreviate(name, 30)}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left" title={address}>{abbreviate(address, 20)}</TableCell>
                                                <TableCell align="left" title={city}>{abbreviate(city, 20)}</TableCell>
                                                <TableCell align="left" title={state}>{abbreviate(state, 20)}</TableCell>
                                                <TableCell align="left" title={country}>{abbreviate(country, 20)}</TableCell>
                                                <TableCell align="left" title={postcode}>{abbreviate(postcode, 20)}</TableCell>
                                                <TableCell align="left" title={description}>{abbreviate(description, 40)}</TableCell>
                                                {isAdmin && <TableCell align="left" title={organizerVenue[0]?.organizer?.name}>{abbreviate(organizerVenue[0]?.organizer?.name, 20)}</TableCell>}
                                                <TableCell align="left">
                                                    <VenueMoreMenu id={row?.id} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {spinner && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                                <div className={classes.fullWidth}>
                                                    <CircularProgress className={classes.textAlign} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                                {!spinner && isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                                <SearchNotFound title="No Venues Found." />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
