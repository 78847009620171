import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import swal from 'sweetalert';
import moment from 'moment-timezone';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { SliderUploadFiles } from '../components/SliderUploadFiles';
import Page from '../components/Page';
import { localStorageEnums } from '../constants/LocalStorageEnums';
import SimpleBackdrop from '../components/Backdrop';
import { MESSAGES } from '../constants/ValidationMsgs';
import '../Styles/Slider.css';
import { useStyles } from '../Styles/components';
import '../Styles/CreateEvent.css';
import DatePickerExampleDisableDates from '../components/DisableDatePicker';
// import TimePickerComponent from '../components/TimePicker';
import {
  duplicateAndFetchEventsDetailsById,
  fetchEventsDetailsById,
  fetchVenueList,
  fetchVenueListByOrgID,
  updateEvent,
} from '../Services/Events.services';
import { convert, convertMDY } from '../utils/formatTime';
import { ROLES } from '../constants/Roles';
import { fetchSports } from '../Services/Organizer.services';
import { fetchOrganizersList } from '../Services/Admin.services';
import { capitalizeFirstLetter, abbreviate } from '../utils/abbreviators';
import { RcTimePicker } from '../components/rc-time-picker';

export const EditEvent = () => {
  const isAdmin = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA)).role === ROLES.ADMIN;
  const { id, action } = useParams();
  const classes = useStyles();
  const [spinner, setSpinner] = useState(false);
  const [isFormatDocsRemove, setIsFormatDocsRemove] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [spinnerDate, setSpinnerDate] = useState(false);
  const [formatFile, setFormatFile] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [daysWeekArray, setDaysWeekArray] = React.useState([]);
  const [individualPriceMsg, setIndividualPriceMsg] = useState('');
  const [teamPriceMsg, setTeamPriceMsg] = useState('');
  const [endTimeError, setEndTimeError] = useState(false);

  const [daysWeek] = React.useState([
    {
      id: 0,
      name: 'Sun',
    },
    {
      id: 1,
      name: 'Mon',
    },
    {
      id: 2,
      name: 'Tue',
    },
    {
      id: 3,
      name: 'Wed',
    },
    {
      id: 4,
      name: 'Thu',
    },
    {
      id: 5,
      name: 'Fri',
    },
    {
      id: 6,
      name: 'Sat',
    }
  ]);

  const [organizerData, setOrganizerData] = useState({
    type: '',
    name: '',
    format_url: '',
    no_of_games: '',
    refree: '',
    age_limit: '',
    registration_link: '',
    format_file_desc: '',
    gender: '',
    venue_selection: [],
    sport_type: '',
    daysWeek: [],
    price_description: '',
    organizerId: '',
    endTimeNull: false,
  });

  const [feesData, setFeesData] = useState({
    priceType: '',
    individualPrice: '',
    teamPrice: '',
  });

  const [feesDataError, setFeesDataError] = useState({
    priceType: false,
    individualPrice: false,
    teamPrice: false,
  });

  const [feesArray, setFeesArray] = useState([]);

  const [eventId, setEventId] = useState();

  const [preloadedFile, setPreloadedFile] = useState(null);

  const [files, setFiles] = useState();

  const [sliderImg, setSliderImg] = useState([]);
  const [organizersList, setOrganizersList] = useState([]);
  const [sliderImgError, setSliderImgError] = useState(false);

  const [deletedSliderIndex, setDeletedSliderIndex] = useState([]);

  const [sports, setSports] = useState([]);

  const formdata = new FormData();

  const [errors, setErrors] = React.useState({
    type: false,
    name: false,
    startDate: false,
    endDate: false,
    startTime: false,
    endTime: false,
    format_url: false,
    format_file_desc: false,
    no_of_games: false,
    refree: false,
    age_limit: false,
    registration_link: false,
    gender: false,
    daysWeek: false,
    venue_selection: false,
    price_description: false,
    price: false,
    sport_type: false,
    organizerId: false,
  });

  const [preloadedImgs, setPreloadedSliderImg] = useState([]);

  const fetchListOrgs = async () => {
    try {
      setSpinner(true);
      const response = await fetchOrganizersList();
      if (response.status === 200) {
        const data = await response.data;
        setOrganizersList(data.data.data);
      }
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
      swal('Error', err.response?.data?.message || '', 'error');
    }
  };

  const fetchListVenueByOrg = async (oid) => {
    try {
      const response = await fetchVenueListByOrgID(oid);
      if (response.status === 200) {
        const data = await response.data;
        setVenuesList(data?.data?.venues || []);
      }
    } catch (err) {
      swal('Error', err.response?.data?.message || '', 'error');
    }
  };

  React.useEffect(() => {
    const fetchList = async () => {
      try {
        setSpinner(true);
        setSpinnerDate(true);
        const response = await fetchEventsDetailsById(id);
        if (response.status === 200) {
          const data = await response.data;
          const event = data?.data?.event;
          await fetchListVenueByOrg(event?.organizer?.userId);
          await fetchListOrgs();
          const venueArr = [];
          const { eventVenue, eventDaysOfWeek } = event;
          for (let i = 0; i < eventVenue?.length; i += 1) {
            venueArr.push(eventVenue[i].venueId);
          }
          setDaysWeekArray(eventDaysOfWeek);
          setVenueName(venueArr);
          setOrganizerData({
            type: event?.type,
            name: event?.name,
            format_url: event?.formatUrl,
            no_of_games: event?.noOfGames,
            refree: event?.refree ? 'true' : 'false',
            age_limit: event?.ageLimit,
            registration_link: event?.registrationLink,
            format_file_desc: event?.formatDescription,
            gender: event?.gender,
            venue_selection: venueArr,
            sport_type: event?.eventSport[0]?.id,
            daysWeek: eventDaysOfWeek,
            price_description: event?.priceDescription,
            organizerId: event?.organizerId,
            endTimeNull: event?.endTimeNull,
          });
          const timezone = event?.eventVenue[0]?.venue?.timezone;
          const cutoffStringStartDate = event?.eventVenue[0]?.startDate;
          const utcCutoffStartDate = moment.utc(cutoffStringStartDate, 'YYYYMMDD HH:mm:ss');
          const displayCutoffStartDate = utcCutoffStartDate.clone().tz(timezone);

          setPreloadedFile(event?.formatUrl);
          setFeesArray(event?.eventPrice);
          setPreloadedSliderImg(event?.eventImage);
          setStartDate(displayCutoffStartDate.format('MM/DD/YYYY hh:mm:ss A'));
          setSliderImgError(event?.eventImage.length === 0);
          setStartTime(displayCutoffStartDate.format('MM/DD/YYYY hh:mm:ss A'));
          if (event?.eventVenue[0]?.endDate) {
            const cutoffStringEndDate = event?.eventVenue[0]?.endDate;
            const utcCutoffEndDate = moment.utc(cutoffStringEndDate, 'YYYYMMDD HH:mm:ss');
            const displayCutoffEndDate = utcCutoffEndDate.clone().tz(timezone);
            setEndTime(event?.endTimeNull ? '' : displayCutoffEndDate.format('MM/DD/YYYY hh:mm:ss A'));
            setEndDate(displayCutoffEndDate.format('MM/DD/YYYY hh:mm:ss A'));
          }
          setSpinnerDate(false);
          setSpinner(false);
        }
      } catch (err) {
        setSpinner(false);
        swal('Error', err?.response?.data?.message, 'error');
      }
    };

    const duplicateEvent = async () => {
      try {
        setSpinner(true);
        setSpinnerDate(true);
        const response = await duplicateAndFetchEventsDetailsById(id);
        if (response.status === 201) {
          const data = await response.data;
          const event = data?.data;
          await fetchListVenueByOrg(event?.organizer?.userId);
          await fetchListOrgs();
          const venueArr = [];
          const { eventVenue, eventDaysOfWeek } = event;
          for (let i = 0; i < eventVenue?.length; i += 1) {
            venueArr.push(eventVenue[i].venueId);
          }
          setDaysWeekArray(eventDaysOfWeek);
          setVenueName(venueArr);
          setEventId(event?.id);
          setOrganizerData({
            type: event?.type,
            name: event?.name,
            format_url: event?.formatUrl,
            no_of_games: event?.noOfGames,
            refree: event?.refree ? 'true' : 'false',
            age_limit: event?.ageLimit,
            registration_link: event?.registrationLink,
            format_file_desc: event?.formatDescription,
            gender: event?.gender,
            venue_selection: venueArr,
            sport_type: event?.eventSport[0]?.id,
            daysWeek: eventDaysOfWeek,
            price_description: event?.priceDescription,
            organizerId: event?.organizerId,
            endTimeNull: event?.endTimeNull,
          });
          const timezone = event?.eventVenue[0]?.venue?.timezone;
          const cutoffStringStartDate = event?.eventVenue[0]?.startDate;
          const utcCutoffStartDate = moment.utc(cutoffStringStartDate, 'YYYYMMDD HH:mm:ss');
          const displayCutoffStartDate = utcCutoffStartDate.clone().tz(timezone);

          setPreloadedFile(event?.formatUrl);
          setFeesArray(event?.eventPrice);
          setPreloadedSliderImg(event?.eventImage);
          setStartDate(displayCutoffStartDate.format('MM/DD/YYYY hh:mm:ss A'));
          setSliderImgError(event?.eventImage.length === 0);
          setStartTime(displayCutoffStartDate.format('MM/DD/YYYY hh:mm:ss A'));
          if (event?.eventVenue[0]?.endDate) {
            const cutoffStringEndDate = event?.eventVenue[0]?.endDate;
            const utcCutoffEndDate = moment.utc(cutoffStringEndDate, 'YYYYMMDD HH:mm:ss');
            const displayCutoffEndDate = utcCutoffEndDate.clone().tz(timezone);
            setEndTime(event?.endTimeNull ? '' : displayCutoffEndDate.format('MM/DD/YYYY hh:mm:ss A'));
            setEndDate(displayCutoffEndDate.format('MM/DD/YYYY hh:mm:ss A'));
          }
          setSpinnerDate(false);
          setSpinner(false);
        }
      } catch (err) {
        setSpinner(false);
        swal('Error', err?.response?.data?.message || '', 'error');
      }
    };

    if (id) {
      if (action === 'edit') fetchList();
      else {
        duplicateEvent();
      }
    }
    // eslint-disable-next-line
  }, [id]);

  React.useEffect(() => {
    const fetchList = async () => {
      try {
        setSpinner(true);
        const response = await fetchSports();
        if (response.status === 200) {
          const data = await response.data;
          setSports(data?.data?.data);
        }
        setSpinner(false);
      } catch (err) {
        setSpinner(false);
        swal('Error', err.response?.data?.message || '', 'error');
      }
    };
    fetchList();
    // eslint-disable-next-line
  }, []);

  const [venuesList, setVenuesList] = useState([]);
  const [venueName, setVenueName] = useState([]);

  React.useEffect(() => {
    const fetchList = async () => {
      try {
        const response = await fetchVenueList();
        if (response.status === 200) {
          const data = await response.data;
          setVenuesList(data?.data?.venues || []);
        }
      } catch (err) {
        swal('Error', err.response?.data?.message || '', 'error');
      }
    };
    if (!isAdmin) fetchList();
    // eslint-disable-next-line
  }, []);

  const handleChange = async (e, prop) => {
    if (prop === 'type') {
      setOrganizerData({
        ...organizerData,
        [prop]: e.target.value,
      });
      if (e.target.value === 'pickup') {
        setOrganizerData({
          ...organizerData,
          no_of_games: '',
        });
      }
      setErrors({
        ...errors,
        [prop]: !e.target.value,
      });
    }
    if (prop === 'format_url') {
      setFiles(e.target.files[0]);
      setFormatFile(e.target.files[0].name);
      setOrganizerData({ ...organizerData, format_url: e.target.files[0] });
      setErrors({ ...errors, format_url: false });
    }

    if (prop === 'startTime') {
      setStartTime(e.target.value);
      setErrors({ ...errors, startTime: false });
    }

    if (prop === 'daysWeek') {
      setDaysWeekArray(typeof e.target.value === 'number' ? e.target.value.split(',') : e.target.value);
      setOrganizerData({ ...organizerData, daysWeek: e.target.value });
    }

    if (prop === 'venue_selection') {
      setVenueName(typeof e.target.value === 'number' ? e.target.value.split(',') : e.target.value);
      setOrganizerData({ ...organizerData, venue_selection: e.target.value });
      setErrors({
        ...errors,
        venue_selection: e.target.value?.length === 0,
      });
    }

    if (prop === 'endTime') {
      setEndTime(e.target.value);
      setErrors({ ...errors, endTime: false });
    }
    if (prop === 'organizerId') {
      setVenuesList([]);
      setVenueName('');
      setOrganizerData({ ...organizerData, venue_selection: [], organizerId: e.target.value });
      setErrors({
        ...errors,
        organizerId: !e.target.value,
      });
      fetchListVenueByOrg(e.target.value);
    } else {
      setOrganizerData({
        ...organizerData,
        [prop]: e.target.value,
      });
      setErrors({
        ...errors,
        [prop]: !e.target.value,
      });
    }
  };

  const openFileSeletor = () => {
    document.getElementById('file').click();
  };

  React.useEffect(() => {
    if (startTime || startDate || endTime)
      setErrors({
        ...errors,
        startTime: !startTime,
        startDate: !startDate,
        endTime: !endTime,
      });
    // eslint-disable-next-line
  }, [startTime, startDate, endTime]);

  const onChangeFeesData = (value, prop) => {
    if (prop === 'priceType') {
      setFeesData({
        ...feesData,
        [prop]: value,
      });

      setFeesDataError({
        ...feesDataError,
        [prop]: !value,
      });
    } else if (prop === 'individualPrice') {
      setFeesData({
        ...feesData,
        [prop]: value,
      });

      setFeesDataError({
        ...feesDataError,
        [prop]: Number(value) <= 0 || (Number(value) >= Number(feesData.teamPrice) && feesData.teamPrice !== ''),
        teamPrice: Number(value) >= Number(feesData.teamPrice) && feesData.teamPrice !== '',
      });

      if (value > feesData.teamPrice && feesData.teamPrice !== '') {
        setIndividualPriceMsg(MESSAGES.MORE_INDIVIDUAL_PRICE_REQUIRED);
        setTeamPriceMsg(MESSAGES.LESS_TEAM_PRICE_REQUIRED);
      }

      if (value === '') {
        setIndividualPriceMsg(MESSAGES.INDIVIDUAL_PRICE_REQUIRED);
        setTeamPriceMsg(MESSAGES.TEAM_PRICE_REQUIRED);
      }
    } else {
      setFeesData({
        ...feesData,
        [prop]: value,
      });

      setFeesDataError({
        ...feesDataError,
        [prop]: Number(value) <= 0 || Number(value) <= feesData.individualPrice,
        individualPrice: Number(value) <= Number(feesData.individualPrice) && feesData.individualPrice !== '',
      });

      if (value < feesData.individualPrice) {
        setIndividualPriceMsg(MESSAGES.MORE_INDIVIDUAL_PRICE_REQUIRED);
        setTeamPriceMsg(MESSAGES.LESS_TEAM_PRICE_REQUIRED);
      }

      if (value === '') {
        setIndividualPriceMsg(MESSAGES.INDIVIDUAL_PRICE_REQUIRED);
        setTeamPriceMsg(MESSAGES.TEAM_PRICE_REQUIRED);
      }
    }
  };

  const pushTickets = () => {
    if (
      feesData.priceType &&
      feesData.individualPrice > 0 &&
      (organizerData?.type === 'pickup'
        ? true
        : feesData.teamPrice > 0 && Number(feesData.teamPrice) >= Number(feesData.individualPrice))
    ) {
      setFeesArray((currentArray) => [...currentArray, feesData]);

      setFeesData({
        priceType: '',
        individualPrice: '',
        teamPrice: '',
      });
    } else {
      setFeesDataError({
        priceType: !feesData.priceType.trim(),
        teamPrice:
          organizerData?.type === 'pickup'
            ? false
            : Number(feesData.teamPrice) <= 0 || Number(feesData.individualPrice) >= Number(feesData.teamPrice),
        individualPrice:
          Number(feesData.individualPrice) <= 0 ||
          (organizerData?.type === 'pickup' ? false : Number(feesData.individualPrice) >= Number(feesData.teamPrice)),
      });
      if (organizerData?.type === 'pickup' ? false : feesData.teamPrice < feesData.individualPrice) {
        setIndividualPriceMsg(MESSAGES.MORE_INDIVIDUAL_PRICE_REQUIRED);
        setTeamPriceMsg(MESSAGES.LESS_TEAM_PRICE_REQUIRED);
      }

      if (feesData.individualPrice === '') setIndividualPriceMsg(MESSAGES.INDIVIDUAL_PRICE_REQUIRED);
      if (feesData.teamPrice === '') setTeamPriceMsg(MESSAGES.TEAM_PRICE_REQUIRED);
    }
  };

  const handleEditInCart = (value, props, id) => {
    const newArr = [...feesArray];
    newArr[id][props] = value;
    setFeesArray(newArr);
  };

  const deleteInCart = (id) => {
    setFeesArray([...feesArray.filter((item, index) => index !== id)]);
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const sizeOfFeesArr = feesArray.length;
      let counter = 0;
      for (let i = 0; i < sizeOfFeesArr; i += 1) {
        if (
          (organizerData?.type === 'pickup'
            ? true
            : Number(feesArray[i].teamPrice) >= Number(feesArray[i].individualPrice)) &&
          feesArray[i].individualPrice >= 0 &&
          (organizerData?.type === 'pickup' ? true : feesArray[i].teamPrice >= 0 && feesArray[i].teamPrice !== '') &&
          feesArray[i].individualPrice !== '' &&
          feesArray[i].typeName !== ''
        ) {
          counter += 1;
        }
      }
      let endDateCp = endDate;
      const strTime = startTime?.$d === undefined ? dayjs(startTime).toString() : dayjs(startTime?.$d).toString();
      const endStrTime = endTime?.$d === undefined ? dayjs(endTime).toString() : dayjs(endTime?.$d).toString();
      const dateFlag = new Date(startDate).getDate() === new Date(endDateCp).getDate();
      const monthFlag = new Date(startDate).getMonth() === new Date(endDateCp).getMonth();
      const yearFlag = new Date(startDate).getFullYear() === new Date(endDateCp).getFullYear();

      const timeFlag = strTime.split(' ')[4] > endStrTime.split(' ')[4];
      const datesFlag = dateFlag && monthFlag && yearFlag;
      setEndTimeError(datesFlag && timeFlag && endTime);
      if (!endDate && !endStrTime.split(' ')[4]) {
        endDateCp = undefined;
      }

      const endDatesFlag = endDateCp ? moment(convertMDY(startDate)) > moment(convertMDY(endDateCp)) : false;
      setEndDateError(endDatesFlag);

      if (
        organizerData?.name?.trim() &&
        organizerData?.age_limit &&
        organizerData?.venue_selection.length > 0 &&
        organizerData?.format_file_desc?.trim() &&
        organizerData?.gender &&
        !(datesFlag && timeFlag) &&
        !endDatesFlag &&
        counter === sizeOfFeesArr &&
        organizerData?.refree &&
        organizerData?.sport_type &&
        organizerData?.venue_selection.length > 0 &&
        organizerData?.type &&
        organizerData?.daysWeek.length > 0 &&
        (isAdmin ? organizerData.organizerId : true) &&
        organizerData?.registration_link?.trim() &&
        organizerData?.sport_type &&
        feesArray.length > 0 &&
        startDate &&
        startTime &&
        (preloadedImgs.length > 0 || sliderImg.length > 0)
      ) {
        const strTime = dayjs(startTime)?.$d.toString();
        const startingDate = convert(startDate);
        const endStrTime = dayjs(endTime)?.$d.toString();
        const endingDate = convert(endDateCp);
        formdata.append('name', organizerData.name);
        formdata.append('start_date', startingDate.concat(' '.concat(strTime.split(' ')[4])));
        if (endDateCp) formdata.append('end_date', endingDate.concat(' '.concat(endStrTime.split(' ')[4])));
        formdata.append('age_limit', organizerData.age_limit);
        formdata.append('end_time_null', !endTime);
        if (isAdmin) formdata.append('organizer_id', organizerData.organizerId);
        formdata.append('registration_link', organizerData.registration_link);
        if (files && formatFile !== '') formdata.append('format_docs', files);
        formdata.append('format_description', organizerData.format_file_desc);
        formdata.append('refree', organizerData.refree);
        formdata.append('is_format_docs_remove', isFormatDocsRemove);
        formdata.append('no_of_games', Number(organizerData.no_of_games));
        formdata.append('gender', organizerData.gender);
        formdata.append('sport_id', Number(organizerData.sport_type));
        formdata.append('timezone', organizerData.timezone);
        formdata.append('is_duplicate', action === 'duplicate');
        formdata.append('type', organizerData.type);
        formdata.append('price_description', organizerData.price_description);
        formdata.append('description', organizerData.description);
        for (let i = 0; i < organizerData.venue_selection.length; i += 1)
          formdata.append('venue_ids[]', organizerData.venue_selection[i]);
        for (let i = 0; i < feesArray.length; i += 1) {
          formdata.append(`prices[${i}][name]`, feesArray[i].priceType);
          formdata.append(`prices[${i}][team_price]`, organizerData?.type === 'pickup' ? 0 : feesArray[i].teamPrice);
          formdata.append(`prices[${i}][individual_price]`, feesArray[i].individualPrice);
        }
        for (let i = 0; i < sliderImg.length; i += 1) formdata.append('slider_images', sliderImg[i]);

        for (let i = 0; i < deletedSliderIndex.length; i += 1)
          formdata.append('delete_images_ids[]', deletedSliderIndex[i]);

        if (sliderImg.length > 0) formdata.append('event_logo', sliderImg[0]);

        for (let i = 0; i < organizerData.daysWeek.length; i += 1)
          formdata.append('days_of_week[]', organizerData.daysWeek[i]);
        const currIdx = action === 'duplicate' ? eventId : id;
        setSpinner(true);
        const response = await updateEvent(currIdx, formdata, {
          Authorization: `Bearer ${window.localStorage.getItem(localStorageEnums.TOKEN)}`,
        });
        const data = await response.data;
        setSpinner(false);
        if (response.status === 200)
          swal('Success', data.message, 'success').then(() => {
            window.location.href = '/dashboard/events';
          });
      } else {
        setSliderImgError(sliderImg.length === 0 && preloadedImgs.length === 0);
        setErrors({
          type: !organizerData?.type,
          name: !organizerData?.name?.trim(),
          startDate: !startDate,
          startTime: !startTime,
          endTime: !endTime,
          format_file_desc: !organizerData?.format_file_desc?.trim(),
          no_of_games: organizerData.type === 'pickup' ? false : !organizerData?.no_of_games,
          refree: !organizerData?.refree,
          age_limit: !organizerData?.age_limit,
          registration_link: !organizerData?.registration_link?.trim(),
          gender: !organizerData?.gender,
          venue_selection: organizerData?.venue_selection.length === 0,
          price_description: !organizerData?.price_description,
          price: feesArray.length === 0,
          daysWeek: organizerData?.daysWeek.length === 0,
          sport_type: !organizerData?.sport_type,
          organizerId: !organizerData?.organizerId,
        });
        setEndTimeError(datesFlag && timeFlag);
        setEndDateError(endDatesFlag);
      }
    } catch (err) {
      setSpinner(false);
      console.log(err);
    }
  };

  const openFilePDF = () => {
    if (!preloadedFile) return;
    window.open(`${process.env.REACT_APP_CLOUDFRONT}/${preloadedFile}`, '_blank', 'noopener,noreferrer');
  };

  const handleDeleteAttachment = () => {
    setIsFormatDocsRemove(true);
    setPreloadedFile(null);
  };

  return (
    <Page title={action === 'edit' ? 'Edit Event' : 'Duplicate Event'}>
      <Container>
        <SimpleBackdrop open={spinner && spinnerDate} />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {action === 'edit' ? 'Edit' : 'Duplicate'} Event
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Card style={{ minHeight: '500px', padding: '20px' }}>
              <Stack spacing={3} style={{ width: '100%' }}>
                {isAdmin && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <FormControl style={{ width: '100%' }} error={errors.organizerId}>
                      <InputLabel id="demo-simple-select-label">Select an Organizer * </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={organizerData?.organizerId || ''}
                        label="Select an Organizer * "
                        error={errors.organizerId}
                        onChange={(e) => handleChange(e, 'organizerId')}
                      >
                        {organizersList?.map((organizer) => (
                          <MenuItem key={organizer?.userId} value={organizer?.userId}>
                            {organizer?.name}
                          </MenuItem>
                        ))}
                        {organizersList?.length === 0 && (
                          <MenuItem value="" disabled>
                            No organizers available.
                          </MenuItem>
                        )}
                      </Select>
                      {errors.organizerId && <FormHelperText>{MESSAGES.ORGANIZER_REQUIRED}</FormHelperText>}
                    </FormControl>
                  </Stack>
                )}
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl style={{ width: '100%' }} error={errors.type}>
                    <InputLabel id="demo-simple-select-label">Type * </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={organizerData.type}
                      label="Type * "
                      error={errors.type}
                      onChange={(e) => handleChange(e, 'type')}
                    >
                      <MenuItem value={'league'}>League</MenuItem>
                      <MenuItem value={'pickup'}>Pickup Game</MenuItem>
                      <MenuItem value={'tournament'}>Tournament</MenuItem>
                    </Select>
                    {errors.type && <FormHelperText>{MESSAGES.EVENT_TYPE_REQUIRED}</FormHelperText>}
                  </FormControl>
                  <TextField
                    color="warning"
                    label="Name * "
                    value={organizerData?.name || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => handleChange(e, 'name')}
                    error={errors.name}
                    helperText={errors.name && MESSAGES.FULLNAME_REQUIRED}
                  />
                </Stack>
                <SliderUploadFiles
                  setSliderImgState={setSliderImg}
                  sliderImgState={sliderImg}
                  preloadedImgs={preloadedImgs}
                  setPreloadedSliderImg={setPreloadedSliderImg}
                  deletedSliderIndex={deletedSliderIndex}
                  setDeletedSliderIndex={setDeletedSliderIndex}
                />
                {sliderImgError && sliderImg.length === 0 && preloadedImgs.length === 0 && (
                  <FormHelperText error={sliderImgError}>{MESSAGES.IMAGES_REQUIRED}</FormHelperText>
                )}
                <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between" mb={5}>
                  <FormControl fullWidth error={errors?.venue_selection}>
                    <InputLabel id="demo-multiple-checkbox-label">Venues Selection * </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={organizerData?.venue_selection}
                      label="Venues Selection * "
                      error={errors?.venue_selection}
                      onChange={(e) => handleChange(e, 'venue_selection')}
                      multiple
                      input={<OutlinedInput label="Venues Selection * " />}
                      renderValue={() => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          <span>
                            {organizerData?.venue_selection?.length > 0
                              ? `${abbreviate(
                                  capitalizeFirstLetter(
                                    venuesList?.find((venue) => venue?.id === organizerData?.venue_selection[0])
                                      ?.name || ''
                                  ),
                                  20
                                )} ${
                                  organizerData?.venue_selection?.length > 1
                                    ? ` + ${organizerData?.venue_selection?.length - 1}`
                                    : ''
                                } `
                              : null}
                          </span>
                        </Box>
                      )}
                    >
                      {venuesList?.map((addon) => (
                        <MenuItem
                          key={addon?.id}
                          value={addon?.id}
                          style={{
                            fontWeight: organizerData?.venue_selection?.find((add) => addon.id === add) ? '600' : '300',
                            backgroundColor: organizerData?.venue_selection?.find((add) => addon.id === add)
                              ? '#faf5e6'
                              : '#fff',
                          }}
                        >
                          <Checkbox checked={venueName?.indexOf(addon?.id) > -1} />
                          <ListItemText primary={addon?.name} />
                        </MenuItem>
                      ))}
                      {venuesList?.length === 0 && (
                        <MenuItem value={0} disabled>
                          <ListItemText primary="No venues added." />
                        </MenuItem>
                      )}
                    </Select>
                    {errors.venue_selection && (
                      <FormHelperText error={errors.venue_selection}>{MESSAGES.VENUE_REQUIRED}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl fullWidth color="warning" error={errors.daysWeek}>
                    <InputLabel id="demo-multiple-checkbox-label">Days Of Week * </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={organizerData.daysWeek}
                      label="Days Of Week * "
                      error={errors.daysWeek}
                      onChange={(e) => handleChange(e, 'daysWeek')}
                      multiple
                      input={<OutlinedInput label="Days Of Week * " />}
                      renderValue={() => (
                        <span>
                          {daysWeekArray.length > 0
                            ? `${daysWeek.find((day) => day.id === organizerData.daysWeek[0])?.name} ${
                                daysWeekArray.length > 1 ? ` + ${daysWeekArray.length - 1}` : ''
                              }`
                            : null}
                        </span>
                      )}
                    >
                      {daysWeek.map((addon) => (
                        <MenuItem
                          key={addon.id}
                          value={addon.id}
                          style={{
                            fontWeight: daysWeekArray?.find((add) => addon.id === add) ? '600' : '300',
                            backgroundColor: daysWeekArray?.find((add) => addon.id === add) ? '#faf5e6' : '#fff',
                          }}
                        >
                          <Checkbox checked={daysWeekArray.indexOf(addon.id) > -1} color="warning" />
                          <ListItemText primary={addon.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.daysWeek && <FormHelperText>{MESSAGES.DAYS_OF_WEEKS_REQUIRED}</FormHelperText>}
                  </FormControl>
                  <FormControl style={{ width: '100%' }} error={errors.sport_type}>
                    <InputLabel id="demo-simple-select-label">Sport Type * </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={organizerData.sport_type}
                      error={errors.sport_type}
                      label="Sport Type * "
                      onChange={(e) => handleChange(e, 'sport_type')}
                    >
                      {sports?.map((sport) => (
                        <MenuItem key={sport?.id} value={sport?.id}>
                          {sport?.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.sport_type && <FormHelperText>{MESSAGES.SPORT_TYPE_REQUIRED}</FormHelperText>}
                  </FormControl>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} spacing={2}>
                  {spinnerDate ? (
                    <div className="circular_center_div">
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      {' '}
                      <DatePickerExampleDisableDates
                        error={errors.startDate}
                        helperText={MESSAGES.STARTDATE_REQUIRED}
                        setData={setStartDate}
                        data={startDate}
                        label="Start Date * "
                      />
                      <DatePickerExampleDisableDates
                        error={endDateError}
                        helperText={MESSAGES.ERROR_ENDDATE_REQUIRED}
                        setData={setEndDate}
                        data={endDate}
                        label="End Date"
                      />
                      <FormControl error={errors.startTime}>
                        <RcTimePicker title="Start Time * " time={startTime} setTime={setStartTime} />
                        {errors.startTime && <FormHelperText>{MESSAGES.STARTTIME_REQUIRED}</FormHelperText>}
                      </FormControl>
                      <FormControl error={errors.endTime || endTimeError}>
                        <RcTimePicker title="End Time" time={endTime} setTime={setEndTime} />
                        {(errors.endTime || endTimeError) && endTime && (
                          <FormHelperText>
                            {endTimeError ? MESSAGES.ERROR_ENDTIME : MESSAGES.ENDTIME_REQUIRED}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </>
                  )}
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    color="warning"
                    label="League rules and season format * "
                    value={organizerData?.format_file_desc || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => handleChange(e, 'format_file_desc')}
                    error={errors.format_file_desc}
                    multiline
                    inputProps={{ maxLength: 999 }}
                    helperText={errors.format_file_desc && MESSAGES.FORMATDESC_REQUIRED}
                  />
                  <div className={classes.uploadAssignmentGrid}>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      name="file"
                      id="file"
                      className="file"
                      onChange={(e) => {
                        handleChange(e, 'format_url');
                        e.target.value = '';
                      }}
                      accept="application/pdf"
                    />

                    <FormControl sx={{ width: '85%' }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        League rules and season format file *
                      </InputLabel>
                      <OutlinedInput
                        style={{ width: '100%' }}
                        id="outlined-adornment-name-registration"
                        type="text"
                        className={classes.eightyWidthTextField}
                        value={preloadedFile && formatFile === '' ? preloadedFile.split('documents/')[1] : formatFile}
                        label="League rules and season format file * "
                        color="primary"
                        disabled
                        endAdornment={
                          preloadedFile && formatFile === '' ? (
                            <InputAdornment position="end">
                              <IconButton title="Preview PDF" aria-label="preview pdf" onClick={openFilePDF} edge="end">
                                <RemoveRedEyeIcon />
                              </IconButton>
                              <IconButton
                                title="Remove PDF"
                                aria-label="remove pdf"
                                onClick={handleDeleteAttachment}
                                edge="end"
                                sx={{ marginLeft: '5px' }}
                              >
                                <CancelOutlinedIcon color="error" />
                              </IconButton>
                            </InputAdornment>
                          ) : null
                        }
                        autoComplete="off"
                        error={errors.format_url}
                        helperText={errors.format_url ? MESSAGES.FORMATURL_REQUIRED : null}
                      />
                    </FormControl>
                    <div>
                      {formatFile === '' ? (
                        <Button variant="contained" onClick={openFileSeletor}>
                          <AttachFileOutlinedIcon />
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() => {
                            setFormatFile('');
                            setOrganizerData({ ...organizerData, format_url: '' });
                          }}
                          color="error"
                        >
                          <CancelOutlinedIcon />
                        </Button>
                      )}
                    </div>
                  </div>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    color="warning"
                    label="No Of Games"
                    disabled={organizerData.type === 'pickup'}
                    value={organizerData?.type !== 'pickup' ? organizerData?.no_of_games : ''}
                    style={{ width: '100%' }}
                    type="number"
                    onChange={(e) => handleChange(e, 'no_of_games')}
                  />
                  <FormControl style={{ width: '100%' }} error={errors.refree}>
                    <InputLabel id="demo-simple-select-label">Referee * </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={organizerData.refree}
                      label="Referee * "
                      error={errors.refree}
                      onChange={(e) => handleChange(e, 'refree')}
                    >
                      <MenuItem value={'true'}>Yes</MenuItem>
                      <MenuItem value={'false'}>No</MenuItem>
                    </Select>
                    {errors.refree && <FormHelperText>{MESSAGES.REFREE_REQUIRED}</FormHelperText>}
                  </FormControl>
                  <FormControl style={{ width: '100%' }} error={errors.age_limit}>
                    <InputLabel id="demo-simple-select-label">Age Limit * </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={organizerData.age_limit}
                      label="Age Limit * "
                      error={errors.age_limit}
                      onChange={(e) => handleChange(e, 'age_limit')}
                    >
                      <MenuItem value={'Above 21'}>Adult Sporting Events: 21 and up</MenuItem>
                      <MenuItem value={'Below 20'}>Youth Sporting Events: 20 and under</MenuItem>
                    </Select>
                    {errors.age_limit && <FormHelperText>{MESSAGES.AGE_LIMIT_REQUIRED}</FormHelperText>}
                  </FormControl>
                  <FormControl style={{ width: '100%' }} error={errors.gender}>
                    <InputLabel id="demo-simple-select-label">Gender * </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={organizerData.gender}
                      label="Gender * "
                      error={errors.gender}
                      onChange={(e) => handleChange(e, 'gender')}
                    >
                      <MenuItem value={'male'}>Male</MenuItem>
                      <MenuItem value={'female'}>Female</MenuItem>
                      <MenuItem value={'coed'}>Co-ed</MenuItem>
                    </Select>
                    {errors.gender && <FormHelperText>{MESSAGES.GENDER_REQUIRED}</FormHelperText>}
                  </FormControl>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    color="warning"
                    label="Registration Link * "
                    value={organizerData?.registration_link || ''}
                    style={{ width: '100%' }}
                    onChange={(e) => handleChange(e, 'registration_link')}
                    error={errors.registration_link}
                    helperText={errors.registration_link && MESSAGES.REGISTRATION_LINK_REQUIRED}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <h4>Price Level</h4>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    color="warning"
                    label="Price Name * "
                    type="text"
                    value={feesData?.priceType || ''}
                    style={{ width: '100%' }}
                    onChange={(e) =>
                      onChangeFeesData(
                        e.target.value.trim().length > 0 ? e.target.value : e.target.value.trim(),
                        'priceType'
                      )
                    }
                    error={feesDataError.priceType}
                    helperText={feesDataError.priceType && MESSAGES.PRICE_NAME_REQUIRED}
                  />
                  <TextField
                    color="warning"
                    label="Individual Price * "
                    type="number"
                    value={feesData?.individualPrice}
                    style={{ width: '100%' }}
                    onChange={(e) => onChangeFeesData(e.target.value.trim(), 'individualPrice')}
                    error={feesDataError.individualPrice}
                    helperText={feesDataError.individualPrice && individualPriceMsg}
                  />
                  <TextField
                    color="warning"
                    label={organizerData?.type === 'pickup' ? 'Team Price' : 'Team Price * '}
                    type="number"
                    disabled={organizerData?.type === 'pickup'}
                    value={feesData?.teamPrice}
                    style={{ width: '100%' }}
                    onChange={(e) => onChangeFeesData(e.target.value.trim(), 'teamPrice')}
                    error={organizerData?.type === 'pickup' ? false : feesDataError.teamPrice}
                    helperText={organizerData?.type !== 'pickup' && feesDataError.teamPrice && teamPriceMsg}
                  />
                  <Button
                    size="large"
                    type="button"
                    onClick={pushTickets}
                    variant="contained"
                    color="warning"
                    style={{ color: 'white' }}
                  >
                    Add
                  </Button>
                </Stack>
                {errors.price && (
                  <FormHelperText error={errors?.price}>{MESSAGES?.EMPTY_PRICE_ARRAY_WARNING}</FormHelperText>
                )}
                {feesArray?.map((fees, index) => (
                  <Stack key={index} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      color="warning"
                      label="Price Name"
                      type="text"
                      value={fees?.priceType || ''}
                      style={{ width: '100%' }}
                      onChange={(e) =>
                        handleEditInCart(
                          e.target.value.trim().length > 0 ? e.target.value : e.target.value.trim(),
                          'priceType',
                          index
                        )
                      }
                      error={!fees.priceType}
                      helperText={!fees.priceType && MESSAGES.PRICE_NAME_REQUIRED}
                    />
                    <TextField
                      color="warning"
                      label="Individual Price"
                      type="number"
                      value={fees?.individualPrice}
                      style={{ width: '100%' }}
                      onChange={(e) => handleEditInCart(e.target.value.trim(), 'individualPrice', index)}
                      error={
                        (organizerData?.type === 'pickup'
                          ? false
                          : Number(fees.individualPrice) > Number(fees.teamPrice)) || fees.individualPrice === ''
                      }
                      helperText={
                        (
                          organizerData?.type === 'pickup'
                            ? false
                            : Number(fees.individualPrice) > Number(fees.teamPrice)
                        )
                          ? MESSAGES.MORE_INDIVIDUAL_PRICE_REQUIRED
                          : fees.individualPrice === '' && MESSAGES.INDIVIDUAL_PRICE_REQUIRED
                      }
                    />
                    <TextField
                      color="warning"
                      label="Team Price"
                      type="number"
                      disabled={organizerData?.type === 'pickup'}
                      value={fees?.teamPrice}
                      style={{ width: '100%' }}
                      onChange={(e) => handleEditInCart(e.target.value.trim(), 'teamPrice', index)}
                      error={
                        organizerData?.type === 'pickup'
                          ? false
                          : Number(fees.individualPrice) > Number(fees.teamPrice) || fees.teamPrice === ''
                      }
                      helperText={
                        organizerData?.type !== 'pickup' &&
                        (Number(fees.individualPrice) > Number(fees.teamPrice)
                          ? MESSAGES.LESS_TEAM_PRICE_REQUIRED
                          : fees.teamPrice === '' && MESSAGES.TEAM_PRICE_REQUIRED)
                      }
                    />
                    <Button
                      size="large"
                      type="button"
                      onClick={() => deleteInCart(index)}
                      variant="contained"
                      color="error"
                      style={{ color: 'white' }}
                    >
                      <HighlightOffOutlinedIcon style={{ color: '#fff' }} />
                    </Button>
                  </Stack>
                ))}
                <TextField
                  color="warning"
                  label="Price Description"
                  type="text"
                  multiline
                  inputProps={{ maxLength: 999 }}
                  value={organizerData?.price_description || ''}
                  style={{ width: '100%' }}
                  onChange={(e) => handleChange(e, 'price_description')}
                />
              </Stack>
            </Card>
          </Grid>
        </Grid>
        <div className="save_button_div">
          <LoadingButton
            size="large"
            type="button"
            onClick={onSubmit}
            variant="contained"
            color="warning"
            style={{ color: 'white', width: '50%' }}
            loading={spinner}
          >
            Save
          </LoadingButton>
        </div>
      </Container>
    </Page>
  );
};
