/* eslint no-console:0 */
import 'rc-time-picker/assets/index.css';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import dayjs from 'dayjs';

export const RcTimePicker = (props) => {
    const format = 'hh:mm a';
    const [time, setTime] = React.useState(props?.time ? moment(props?.time) : "");
    const onChange = (value) => {
        setTime(value ? dayjs(value) : "");
        props.setTime(value ? dayjs(value) : "");
    }

    return <TimePicker
        showSecond={false}
        placeholder={props.title}
        defaultValue={time}
        className="xxx"
        onChange={onChange}
        format={format}
        use12Hours
    />
}


RcTimePicker.propTypes = {
    setTime: PropTypes.any,
    time: PropTypes.any,
    title: PropTypes.any
};