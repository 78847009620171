import axios from "axios";
import { headers } from "../utils/headers";
import instance from "../Interceptor";
import { fixedEncodeURIComponent } from "../utils/abbreviators";

export const createEvent = async (formdata, headerToken) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event`, {
        method: "POST",
        headers: { ...headerToken, "Content-Type": "multipart/form-data" },
        data: formdata
    });
    return response;
}

export const createVenue = async (formdata, headerToken) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/venue`, {
        method: "POST",
        headers: headerToken,
        data: formdata
    });
    return response;
}

export const updateVenue = async (id, formdata, headerToken) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/venue/${id}`, {
        method: "PATCH",
        headers: { ...headerToken, "Content-Type": "multipart/form-data" },
        data: formdata
    });
    return response;
}

export const updateEvent = async (id, formdata, headerToken) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event/${id}`, {
        method: "PUT",
        headers: headerToken,
        data: formdata
    });
    return response;
}

export const deleteEventService = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event/${id}`, {
        method: "DELETE",
        headers
    });
    return response;
}

export const fetchEventsList = async (page, search, id) => {
    const url = fixedEncodeURIComponent(search);
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event/organizer/${id}?page=${page + 1}&search=${url}`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchEventsListByAdmin = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event/organizer/${id}`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchVenueList = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/venue`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchVenueListBySearch = async (search, page) => {
    const url = fixedEncodeURIComponent(search);
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/venue?page=${page + 1}&search=${url}`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchVenueListByOrgID = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/venue?organizer_id=${id}`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchVenueDetailsById = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/venue/${id}`, {
        method: "GET",
        headers
    });
    return response;
}


export const fetchEventsDetailsById = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event/${id}`, {
        method: "GET",
        headers
    });
    return response;
}

export const duplicateAndFetchEventsDetailsById = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/event/duplicate/${id}`, {
        method: "POST",
        headers
    });
    return response;
}

export const getAddress = async (data) => {
    const { latitude, longitude } = data;
    const placeInfo = await getPlacesFromCoordinates(latitude, longitude);
    const countryObj = placeInfo?.results[0]?.address_components.filter((address) => address.types.includes('country'));
    const country = countryObj.length ? countryObj[0].long_name : null;
    const stateObj = placeInfo?.results[0]?.address_components.filter((address) =>
        address.types.includes('administrative_area_level_1')
    );
    const state = stateObj.length ? stateObj[0].long_name : null;
    const cityObj = placeInfo?.results[0]?.address_components.filter((address) => address.types.includes('locality'));
    const city = cityObj.length ? cityObj[0].long_name : null;
    const postcodeObj = placeInfo?.results[0]?.address_components.filter((address) => address.types.includes('postal_code'));
    const postcode = postcodeObj.length ? postcodeObj[0].long_name : null;
    const venue = placeInfo?.results[0]?.formatted_address;
    const streetObj = placeInfo?.results[0]?.formatted_address.split(',');
    const street = streetObj.slice(0, streetObj.length - 2).toString();
    return {
        country,
        state,
        postcode,
        city,
        venue,
        street
    };
};

export const getPlaceDetails = (placeInfo, isGeocodingAPI) => {
    if (!isGeocodingAPI) {
        return {
            title: `${placeInfo?.result?.address_components[0]?.long_name || '-'}, ${placeInfo?.result?.address_components[1]?.long_name || '-'
                }`,
            mapId: placeInfo.result?.place_id,
            placeLocation: placeInfo.result?.geometry?.location,
        };
    }
    return {
        title: `${placeInfo?.results[0]?.address_components[0]?.long_name || '-'
            }, ${placeInfo?.results[0]?.address_components[1]?.long_name || '-'}`,
        mapId: placeInfo.results[0]?.place_id,
        placeLocation: placeInfo.results[0]?.geometry?.location,
    };
};

export const getPlacesFromCoordinates = async (lat, lng) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GCP_KEY}`;
    const response = await axios.get(url);
    return response.data;
};

export const getPlacesFromText = async () => {
    const url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=atliq&key=${process.env.REACT_APP_GCP_KEY}`;
    const response = await axios.get(url);
    return response.data;
};
