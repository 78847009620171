import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import React from 'react';
import './Style.css';
import profileImg from '../Images/profile.jpg';
import { MESSAGES } from '../constants/ValidationMsgs';
import { fileExtensions, verifyFileExtension } from '../utils/validators';

const Profile = ({ onSubmit, src }) => (
  <div className="card">
    <form onSubmit={onSubmit}>
      <h1>Profile Card</h1>
      <div className="img-wrap">
        <img alt="profile" src={src} />
      </div>
    </form>
  </div>
);

Profile.propTypes = {
  src: PropTypes.any,
  onSubmit: PropTypes.any,
};

const Edit = ({ onSubmit, children }) => (
  <div className="card">
    <form onSubmit={onSubmit}>{children}</form>
  </div>
);

Edit.propTypes = {
  children: PropTypes.any,
  onSubmit: PropTypes.any,
};

export const ImgUpload = (props) => (
  <label htmlFor="photo-upload" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      {props.loading ? <CircularProgress /> : <img htmlFor="photo-upload" src={props?.src} alt="Hii..." />}
    </div>
    <input
      id="photo-upload"
      type="file"
      accept={fileExtensions.onlyImage}
      style={{ display: 'none' }}
      onChange={props?.onChange}
    />
  </label>
);

ImgUpload.propTypes = {
  src: PropTypes.any,
  onChange: PropTypes.any,
  loading: PropTypes.any,
};

export const CardProfile = (props) => {
  const [profileState, setProfileState] = React.useState({
    file: '',
    imagePreviewUrl: profileImg,
    name: '',
    status: '',
    active: 'edit',
  });

  React.useEffect(() => {
    if (props?.src)
      setProfileState({
        ...profileState,
        imagePreviewUrl: `${process.env.REACT_APP_CLOUDFRONT}/${props.src}`,
      });
    // eslint-disable-next-line
  }, [props?.src]);

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (!file.type.includes('image')) {
      swal('Info', MESSAGES.IMG_VALIDATIONS, 'info');
      return;
    }
    if (!verifyFileExtension(file?.name?.toLowerCase())) {
      swal('Info', MESSAGES.IMG_VALIDATIONS, 'info');
      return;
    }
    props.setProfileImg(file);
    reader.onloadend = () => {
      setProfileState({
        ...profileState,
        file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const activeP = profileState.active === 'edit' ? 'profile' : 'edit';
    setProfileState({
      ...profileState,
      active: activeP,
    });
  };
  return (
    <div>
      {profileState.active === 'edit' ? (
        <Edit onSubmit={handleSubmit}>
          <ImgUpload loading={props.loading} onChange={photoUpload} src={profileState.imagePreviewUrl} />
        </Edit>
      ) : (
        <Profile
          onSubmit={handleSubmit}
          src={profileState.imagePreviewUrl}
          name={profileState.name}
          status={profileState.status}
        />
      )}
    </div>
  );
};

CardProfile.propTypes = {
  setProfileImg: PropTypes.any,
  src: PropTypes.any,
  loading: PropTypes.any,
};
