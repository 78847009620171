import PropTypes from 'prop-types';
import { Paper, Typography } from '@mui/material';

SearchNotFound.propTypes = {
  title : PropTypes.string
};

export default function SearchNotFound({ title="Not Found", ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {title}
      </Typography>
    </Paper>
  );
}
