import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import exportFromJSON from "export-from-json";
import DownloadingIcon from '@mui/icons-material/Downloading';
import swal from "sweetalert";
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import Page from '../components/Page';
import { DateRangerFilter } from "../components/DateRangerFilter";
import {
  AppWidgetSummary,
  AppCurrentVisits,
  AppConversionRates
} from '../sections/@dashboard/app';
import { localStorageEnums } from '../constants/LocalStorageEnums';
import { ROLES } from '../constants/Roles';
import { updateAdminDashboard } from "../store/features/Data";
import "../Styles/DashboardApp.css";
import { getDashboardDetails, getGraphByFilter, getUserAll } from "../Services/Dashboard.services";
import { convertYMDbyHyphen } from "../utils/formatTime";
import { useStyles } from '../Styles/components';
import SimpleBackdrop from "../components/Backdrop";

export default function DashboardApp() {
  const classes = useStyles();
  const theme = useTheme();
  const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));

  const [adminDashboardData, setAdminDashboardData] = React.useState({});

  const [adminDashboardGraph, setAdminDashboardGraph] = React.useState({
    newRegisterUsers: 0,
    churnRatio: 0,
    retentionRatio: 0,
    eventRegisters: [],
    appDownloads: []
  });

  const [organizerDashboardGraph, setOrganizerDashboardGraph] = React.useState({
    deviceEventRegister: {
      mobile: 0,
      web: 0
    },
    eventRegisters: []
  });

  const [adminDashboardSpinner, setAdminDashboardSpinner] = React.useState(true);
  const [csvLoader, setCSVLoader] = React.useState(false);

  const [datePickerRangeState, setDatePickerRangeState] = React.useState([
    {
      startDate: new Date("11/01/2022"),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [userSpinner, setUserSpinner] = React.useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [applied, setApplied] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearFilter = () => {
    setApplied(false);
    setDatePickerRangeState([
      {
        startDate: new Date("11/01/2022"),
        endDate: new Date(),
        key: 'selection'
      }
    ])
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const dispatch = useDispatch();
  const adminDashboard = useSelector((state) => state.dataManager.adminsDashboard);

  React.useEffect(() => {

    const fetchOrganizers = async () => {
      try {
        setAdminDashboardSpinner(true);
        const response = await getDashboardDetails();
        if (response.status === 200) {
          const data = await response.data;
          setAdminDashboardData(data?.data || {});
          dispatch(updateAdminDashboard(data?.data || 0));
          setAdminDashboardSpinner(false);
        }
      } catch (err) {
        swal("Error", err.response?.data?.message || "", "error");
        setAdminDashboardSpinner(false);
      }
    }
    if (userData?.role === ROLES.ADMIN) {
      if (!adminDashboard)
        fetchOrganizers()
      else {
        setAdminDashboardData(adminDashboard);
        setAdminDashboardSpinner(false);
      }
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const end = (convertYMDbyHyphen(datePickerRangeState[0].endDate));
    const start = (convertYMDbyHyphen(datePickerRangeState[0].startDate));
    const fetchGraphByFilter = async () => {
      try {
        setUserSpinner(true);
        const response = await getGraphByFilter(start, end);
        const data = await response.data;
        if (userData?.role === ROLES.ADMIN)
          setAdminDashboardGraph(data?.data);
        else
          setOrganizerDashboardGraph(data?.data);
        setUserSpinner(false);
      } catch (err) {
        swal("Error", err.response?.data?.message || "", "error");
        setUserSpinner(false);
      }
    }
    fetchGraphByFilter();
    // eslint-disable-next-line
  }, [datePickerRangeState]);

  const ExportToExcel = async (type) => {
    try {
      setCSVLoader(true);
      const response = await getUserAll();
      if (response.status === 200) {
        const {data} = await response.data;
        if(type === "users")
        exportFromJSON({ data: data.users, fileName: 'Users', exportType: 'csv' })
        else  
          exportFromJSON({ data: data.organizers, fileName: 'Organizers', exportType: 'csv' })
        setCSVLoader(false);
      }
    } catch (err) {
      swal("Error", err.response?.data?.message || "", "error");
      console.log(err);
      setCSVLoader(false);
    }
  } 

  return (
    <Page title="Dashboard" goBack={false}>
      <Container maxWidth="xl">
        <SimpleBackdrop open={csvLoader} />
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        {userData?.role === ROLES.ADMIN && <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary loading={adminDashboardSpinner} title="Daily Users" total={adminDashboardData?.dailyActiveUsers || 0} color="warning" icon={'material-symbols:emoji-people-rounded'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary loading={adminDashboardSpinner} title="Monthly Users" total={adminDashboardData?.monthlyActiveUsers || 0} color="secondary" icon={'charm:people'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary loading={adminDashboardSpinner} title="Total Users" total={adminDashboardData?.totalUsers || 0} color="warning" icon={'bi:people-fill'}>
              <Button style={{ position: 'absolute', top: '0', marginTop: '15px', right: '0' }} onClick={() => ExportToExcel("users")}><DownloadingIcon  /></Button>
              </AppWidgetSummary>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary loading={adminDashboardSpinner} title="Total Organizers" total={adminDashboardData?.totalOrganizers || 0} color="secondary" icon={'fluent:people-audience-24-filled'}>
              <Button style={{ position: 'absolute', top: '0', marginTop: '15px', right: '0' }} onClick={() => ExportToExcel("organizers")}><DownloadingIcon color="secondary" /></Button>
              </AppWidgetSummary>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="apply_filter_div">
              <Button style={{ maxHeight: '52px' }} aria-describedby={id} variant="contained" onClick={handleClick}>
                {applied ? `${moment(datePickerRangeState[0]?.startDate).format('l')} to ${moment(datePickerRangeState[0]?.endDate).format('l')}` : <><TuneIcon style={{ marginRight: '10px' }} /> Apply Filter</>}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <DateRangerFilter setApply={setApplied} value={datePickerRangeState} setValue={setDatePickerRangeState} />
              </Popover>
              {applied && <Button onClick={clearFilter} >Clear</Button>}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary loading={userSpinner} title="New Registration" total={adminDashboardGraph?.newRegisterUsers} color="warning" icon={'medical-icon:i-registration'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary loading={userSpinner} title="Churn Ratio" total={adminDashboardGraph?.churnRatio} color="secondary" icon={'material-symbols:humidity-percentage-rounded'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary loading={userSpinner} title="Retention Ratio" total={adminDashboardGraph?.retentionRatio} color="warning" icon={'material-symbols:humidity-percentage-rounded'} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            {adminDashboardGraph.appDownloads.length > 0 ? <AppCurrentVisits title="App Downloads"
              chartData={adminDashboardGraph?.appDownloads}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            /> :
              <Card className={classes.graphCard}>
                <p>No Data Available</p>
              </Card>}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            {
              !userSpinner ?
                <AppConversionRates
                  datePickerRangeState={datePickerRangeState}
                  title="Clicks groups by Sports Category"
                  subheader=""
                  chartData={adminDashboardGraph?.eventRegisters}
                /> :
                <Card className={classes.graphCardMinH}>
                  <p>No Data Available</p>
                </Card>
            }
          </Grid>
        </Grid>}

        {userData?.role === ROLES.ORGANIZER && <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <div className="apply_filter_div">
              <Button style={{ maxHeight: '52px' }} aria-describedby={id} variant="contained" onClick={handleClick}>
                {applied ? `${moment(datePickerRangeState[0]?.startDate).format('l')} to ${moment(datePickerRangeState[0]?.endDate).format('l')}` : <><TuneIcon style={{ marginRight: '10px' }} /> Apply Filter</>}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <DateRangerFilter setApply={setApplied} value={datePickerRangeState} setValue={setDatePickerRangeState} />
              </Popover>
              {applied && <Button onClick={clearFilter} >Clear</Button>}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AppWidgetSummary loading={userSpinner} title="Mobile Clicks" total={organizerDashboardGraph.deviceEventRegister.mobile} color="info" icon={'ant-design:mobile-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AppWidgetSummary loading={userSpinner} title="Website Clicks" total={organizerDashboardGraph.deviceEventRegister.web} color="warning" icon={'entypo:laptop'} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {
              (!userSpinner && organizerDashboardGraph?.eventRegisters?.length > 0) ?
                <AppConversionRates
                  datePickerRangeState={datePickerRangeState}
                  title="Clicks groups by Sports Category"
                  subheader=""
                  chartData={organizerDashboardGraph?.eventRegisters}
                /> :
                <Card className={classes.graphCardMinH}>
                  <p>No Data Available</p>
                </Card>
            }
          </Grid>

        </Grid>}
      </Container>
    </Page>
  );
}
