import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, IconButton , Stack} from '@mui/material';
import * as Sentry from "@sentry/react";
import { localStorageEnums } from '../../constants/LocalStorageEnums';
import MenuPopover from '../../components/MenuPopover';
import account from '../../_mock/account';
import { abbreviate } from '../../utils/abbreviators';
import { ROLES } from '../../constants/Roles';

export default function AccountPopover() {

  const userData = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA));

  const MENU_OPTIONS = [
    {
      label: 'Profile',
      icon: 'eva:person-fill',
      linkTo: "my-profile",
    }
  ];

  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const logoutHandler = () =>{
    window.localStorage.removeItem(localStorageEnums.TOKEN);
    window.localStorage.removeItem(localStorageEnums.USERDATA);
    window.localStorage.removeItem(localStorageEnums.USERID);
    Sentry.setUser(null);
    window.location.reload();
  }
 
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={userData.organizerLogo ? `${process.env.REACT_APP_CLOUDFRONT}/${userData.organizerLogo}` : account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {abbreviate(userData?.name,16) || "No Name"}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {abbreviate(userData?.email,16) ||"No Name"}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {userData?.role === ROLES.ORGANIZER && <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={logoutHandler} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
