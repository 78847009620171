import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Page = forwardRef(({ children, title = '', disabled = false, goBack = true, meta, ...other }, ref) => {
  const navigate = useNavigate();
  return <>
    <Helmet>
      <title>{`${title} | Assymbly`}</title>
      {meta}
    </Helmet>
    {goBack && <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={-3} display={window.innerWidth > 500 ? "block" : "none"}>
      <Button disabled={disabled} onClick={() => navigate(-1)}><ArrowBackIcon /></Button>
    </Stack>}

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
  goBack: PropTypes.any,
  disabled: PropTypes.any
};

export default Page;
