// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Organizers',
    path: '/dashboard/organizers',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'My Venues',
    path: '/dashboard/venues',
    icon: getIcon('material-symbols:stadium-outline'),
  },
  {
    title: 'My Events',
    path: '/dashboard/events',
    icon: getIcon('carbon:ibm-cloud-event-notification'),
  },
  {
    title: 'video/image',
    path: '/dashboard/video',
    icon: getIcon('clarity:video-gallery-solid'),
  },
  {
    title: 'Broadcast Notification',
    path: '/dashboard/broadcast-notification',
    icon: getIcon('ant-design:notification-filled'),
  }
];

export default navConfig;
