import instance from "../Interceptor";
import { headers } from "../utils/headers";

export const fetchOrganizerInfoByID = async (id) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/organizer/info/${id}`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchSports = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/sport/list`, {
        method: "GET",
        headers
    });
    return response;
}

export const fetchAmenities = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/amenity/list`, {
        method: "GET",
        headers
    });
    return response;
}


export const updateOrganizer = async (formdata, headerToken) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/organizer/update`, {
        method: "POST",
        headers : headerToken,
        data : formdata
    });
    return response;
}