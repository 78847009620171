import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="200" height="40" viewBox="0 0 271 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.1123 21.3043L0 35H28.5027L17.3209 16.087L20.6096 10.4348L34.6417 35H41L20.6096 0L11.1818 16.087L19.0749 29.5652H12.9358L8.1123 21.3043Z" fill="#1F1F1F" />
        <path d="M67.176 30.988L68.716 34.796H73.448L65.076 15.196H60.512L52 34.796H56.564L58.104 30.988H67.176ZM65.748 27.432H59.56L62.668 19.816L65.748 27.432Z" fill="#1F1F1F" />
        <path d="M91.5447 15C87.0087 15 83.9567 17.24 83.9567 20.796C83.9567 27.74 94.4007 25.584 94.4007 29.28C94.4007 30.484 93.3087 31.156 91.4887 31.156C89.4167 31.156 86.5607 30.008 84.5447 28.216L82.7807 31.828C84.9927 33.704 88.2127 34.936 91.4327 34.936C95.7727 34.936 99.1047 32.864 99.1047 29.028C99.1327 21.972 88.6887 23.988 88.6887 20.376C88.6887 19.312 89.6967 18.78 91.1807 18.78C92.7487 18.78 95.2127 19.564 97.2847 20.796L98.9927 17.128C97.0327 15.84 94.2887 15 91.5447 15Z" fill="#1F1F1F" />
        <path d="M118.373 15C113.837 15 110.785 17.24 110.785 20.796C110.785 27.74 121.229 25.584 121.229 29.28C121.229 30.484 120.137 31.156 118.317 31.156C116.245 31.156 113.389 30.008 111.373 28.216L109.609 31.828C111.821 33.704 115.041 34.936 118.261 34.936C122.601 34.936 125.933 32.864 125.933 29.028C125.961 21.972 115.517 23.988 115.517 20.376C115.517 19.312 116.525 18.78 118.009 18.78C119.577 18.78 122.041 19.564 124.113 20.796L125.821 17.128C123.861 15.84 121.117 15 118.373 15Z" fill="#1F1F1F" />
        <path d="M147.469 28.468L154.609 15.196H150.157L145.257 23.988L140.245 15.196H135.793L143.045 28.58V34.796H147.469V28.468Z" fill="#1F1F1F" />
        <path d="M186.961 34.796V15.196H182.033L176.349 27.18L170.637 15.196H165.681V34.796H169.685V21.58L174.893 32.808H177.777L182.985 21.58V34.796H186.961Z" fill="#1F1F1F" />
        <path d="M200.303 15.196V34.796H209.626C214.19 34.796 217.018 32.724 217.018 29.364C217.018 26.788 215.45 24.884 212.93 24.408C215.03 23.876 216.318 22.224 216.318 20.096C216.318 17.072 213.63 15.196 209.318 15.196H200.303ZM204.727 18.808H209.094C210.746 18.808 211.783 19.564 211.783 20.908C211.783 22.28 210.746 23.092 209.094 23.092H204.727V18.808ZM204.727 26.508H209.094C211.194 26.508 212.454 27.376 212.454 28.804C212.454 30.316 211.194 31.184 209.094 31.184H204.727V26.508Z" fill="#1F1F1F" />
        <path d="M233.66 30.932V15.196H229.236V34.796H242.06V30.932H233.66Z" fill="#1F1F1F" />
        <path d="M263.121 28.468L270.261 15.196H265.809L260.909 23.988L255.897 15.196H251.445L258.697 28.58V34.796H263.121V28.468Z" fill="#1F1F1F" />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
