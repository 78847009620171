import instance from "../Interceptor";
import { headers } from "../utils/headers";


export const getDashboardDetails = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/dashboard`, {
        method: "GET",
        headers
    });
    return response;
}

export const getUserAll = async () => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/admin/users`, {
        method: "GET",
        headers
    });
    return response;
}


export const getGraphByFilter = async (startDate, endDate) => {
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/dashboard`, {
        method: "POST",
        headers,
        data: JSON.stringify({
            "start_date": `${startDate} 00:00:00`,
            "end_date": `${endDate} 00:00:00`
        })
    });
    return response;
}

export const fetchGraphDetailsById = async (id, page, organizerId, filterName, startDate, endDate) => {
    const body = {
        "sport_id": Number(id),
        "page": page + 1,
        "search": filterName,
        "start_date": `${startDate} 00:00:00`,
        "end_date": `${endDate} 00:00:00`
    }
    if (organizerId !== '')
        body.organizer_id = organizerId;
    const response = await instance(`${process.env.REACT_APP_SERVER_API}/dashboard/event-register-detail`, {
        method: "POST",
        headers,
        data: JSON.stringify(body)
    });
    return response;
}

