import PropTypes from 'prop-types';
import { useState } from 'react';
import swal from 'sweetalert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import TransitionsModal from './fadingPopUpWrapper';
import { useStyles } from '../../Styles/components';
import { OtpModal } from './otpPopper';
import { ResetPassword } from './resetPassword';
import { AuthForgotPasswordServices } from '../../Services/Auth.services';
import { isValidEmail } from '../../utils/validators';
import { MESSAGES } from '../../constants/ValidationMsgs';


export const ForgotPasswordEmailModal = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    email: ''
  });

  const [otp, setOtp] = useState('');

  const [spinner, setSpinner] = useState(false);

  const [openOTPModal, setOpenOTPModal] = useState(false);

  const [openRestPWDModal, setOpenRestPWDModal] = useState(false);

  const [errors, setErrors] = useState({
    email: false
  });
  const [errorsMsg, setErrorsMsg] = useState({
    email: 'Email is required.'
  });

  const handleChange = (event) => {
    setValues({ ...values, email: event.target.value.trim() });
    setErrors({ email: !isValidEmail(event.target.value) });
    if (event.target.value.length > 0)
      setErrorsMsg({
        email: !isValidEmail(event.target.value) ? MESSAGES.INVALID_EMAIL : MESSAGES.EMAIL_REQUIRED
      });
  };

  const submitHandler = async () => {
    if (isValidEmail(values?.email)) {
      setSpinner(true);
      try {
        const response = await AuthForgotPasswordServices(values);
        setSpinner(false);
        if (response.status === 201) {
          setOpenOTPModal(true);
        }
      } catch (err) {
        setSpinner(false);
        swal('Error', err.response?.data?.message, 'error');
      }
    }
    else {
      setErrors({
        email: true
      })
    }
  };

  const resetAndClose = () => {
    setOpenOTPModal(false);
    setValues({
      email: ''
    });
    setOpenRestPWDModal(false);
    props?.handleClose();
  }

  return (
    <TransitionsModal open={props?.open} handleClose={props?.handleClose} handleOpen={props?.handleOpen}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Recover your account
      </Typography>
      {openOTPModal && openRestPWDModal && <ResetPassword email={values?.email} handleClose={() => resetAndClose()} otp={otp} />}

      {openOTPModal && !openRestPWDModal && <OtpModal email={values?.email} saveOtp={setOtp} setOpenRestPWDModal={setOpenRestPWDModal} handleClose={props?.handleClose} />}

      {!openOTPModal && !openRestPWDModal && <>  <TextField
        id="outlined-adornment-name-registration"
        type="text"
        className={classes.fullWidthTextField}
        value={values.email}
        onChange={handleChange}
        label="Email"
        color="primary"
        autoComplete="off"
        error={errors.email}
        helperText={errors.email ? errorsMsg.email : null}
      />
        <div className={classes.flexDivWithCenter}>
          {spinner ? (
            <CircularProgress className={classes.buttonMargin} />
          ) : (
            <Button variant="contained" className={classes.buttonMargin} onClick={submitHandler}>
              Send
            </Button>
          )}
        </div> </>}

    </TransitionsModal>

  );
};


ForgotPasswordEmailModal.propTypes = {
  handleClose: PropTypes.any,
  handleOpen: PropTypes.any,
  open: PropTypes.any
};