import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const organizerNavConfig = [
    {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
        title: 'My Venues',
        path: '/dashboard/venues',
        icon: getIcon('material-symbols:stadium-outline'),
    },
    {
        title: 'My Events',
        path: '/dashboard/events',
        icon: getIcon('carbon:ibm-cloud-event-notification'),
    }
];

export default organizerNavConfig;
