import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import swal from 'sweetalert';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import { LoadingButton } from '@mui/lab';
import { SliderUploadFiles } from '../components/SliderUploadFiles';
import Page from '../components/Page';
import { localStorageEnums } from '../constants/LocalStorageEnums';
import SimpleBackdrop from '../components/Backdrop';
import { MESSAGES } from '../constants/ValidationMsgs';
import '../Styles/Slider.css';
import { ROLES } from '../constants/Roles';
import '../Styles/CreateEvent.css';
import { createVenue } from '../Services/Events.services';
import { fetchAmenities } from '../Services/Organizer.services';
import { googleAutocompleteStyles } from '../Styles/googleAutocompleteStyling';
import { fetchOrganizersList } from '../Services/Admin.services';
import { useStyles } from '../Styles/components';

export const CreateVenue = () => {
    const classes = useStyles();
    const isAdmin = JSON.parse(window.localStorage.getItem(localStorageEnums.USERDATA)).role === ROLES.ADMIN;
    const [spinner, setSpinner] = useState(false);

    const [apiPlaceData, setApiPlaceData] = useState();

    const [venueData, setVenueData] = useState({
        name: '',
        description: '',
        address: '',
        city: '',
        country: '',
        venue: '',
        state: '',
        timezone: '',
        postcode: '',
        lat: '',
        long: '',
        amenity_selection: [],
        organizerId: ''
    });

    const [sliderImg, setSliderImg] = useState([]);
    const [organizersList, setOrganizersList] = useState([]);

    const [sliderImgError, setSliderImgError] = useState(false);

    const [personName, setPersonName] = useState([]);

    const [amenities, setAmenities] = useState([]);

    const formdata = new FormData();

    const [errors, setErrors] = React.useState({
        name: false,
        address: false,
        city: false,
        country: false,
        state: false,
        postcode: false,
        timezone: false,
        lat: false,
        long: false,
        description: false,
        amenity_selection: false,
        organizerId: false
    });

    const isAllSelected =
        amenities.length > 0 && personName.length === amenities.length;

    React.useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                const response = await fetchOrganizersList();
                if (response.status === 200) {
                    const data = await response.data;
                    setOrganizersList(data.data.data);
                }
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        if (isAdmin)
            fetchList();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        const fetchList = async () => {
            try {
                setSpinner(true);
                const response = await fetchAmenities();
                if (response.status === 200) {
                    const data = await response.data;
                    setAmenities(data.data);
                }
                setSpinner(false);
            } catch (err) {
                setSpinner(false);
                swal('Error', err.response?.data?.message || "", 'error');
            }
        };
        fetchList();
        // eslint-disable-next-line
    }, []);

    const handleChange = (e, prop) => {

        if (prop === "amenity_selection") {
            let lists = [];
            const { value } = e.target;
            if (value[value.length - 1] === "all") {
                if (personName.length !== amenities.length) {
                    for (let i = 0; i < amenities.length; i += 1) {
                        lists.push(amenities[i].id)
                    }
                    setPersonName(lists);
                }
                else {
                    setPersonName([]);
                }
                setVenueData({ ...venueData, amenity_selection: lists });
                setErrors({
                    ...errors,
                    [prop]: lists.length === 0,
                });
                return;
            }
            setPersonName(
                typeof e.target.value === 'number' ? e.target.value.split(',') : e.target.value
            );
            lists = e.target.value;
            setVenueData({ ...venueData, amenity_selection: lists });
            setErrors({
                ...errors,
                [prop]: lists.length === 0,
            });
        }

        else {
            setVenueData({
                ...venueData,
                [prop]: e.target.value,
            });
            setErrors({
                ...errors,
                [prop]: !e.target.value,
            });
        }
    };

    React.useEffect(() => {
        if (apiPlaceData) {
            geocodeByPlaceId(apiPlaceData.value.place_id)
                .then(results => {
                    const countryObj = results[0].address_components.filter((address) => address.types.includes('country'));
                    const country = countryObj.length ? countryObj[0].long_name : null;
                    const stateObj = results[0].address_components.filter((address) => address.types.includes('administrative_area_level_1'));
                    const state = stateObj.length ? stateObj[0].long_name : null;
                    const cityObj = results[0].address_components.filter((address) => address.types.includes('locality'));
                    const city = cityObj.length ? cityObj[0].long_name : null;
                    const postcodeObj = results[0].address_components.filter((address) => address.types.includes('postal_code'));
                    const postcode = postcodeObj.length ? postcodeObj[0].long_name : null;
                    const venue = results[0].formatted_address;
                    const latitude = results[0].geometry.location.lat();
                    const longitude = results[0].geometry.location.lng();
                    setErrors({
                        ...errors,
                        address: !venue,
                        city: !city,
                        country: !country,
                        state: !state,
                        postcode: !postcode,
                        lat: !latitude,
                        long: !longitude
                    });

                    setVenueData({
                        ...venueData,
                        address: venue,
                        city,
                        country,
                        state,
                        postcode,
                        lat: latitude,
                        long: longitude,
                    });

                })
                .catch(error => console.error(error));
        }
        // eslint-disable-next-line
    }, [apiPlaceData]);

    const onSubmit = async (e) => {
        try {
            e.preventDefault();
            if (
                venueData?.name?.trim() &&
                venueData?.address?.trim() &&
                venueData?.amenity_selection.length > 0 &&
                venueData?.city?.trim() &&
                venueData?.postcode?.trim() &&
                venueData?.timezone?.trim() &&
                venueData?.state?.trim() &&
                venueData?.country?.trim() &&
                (isAdmin ? venueData.organizerId : true) &&
                venueData?.lat &&
                venueData?.long &&
                sliderImg.length > 0
            ) {
                setSpinner(true);
                formdata.append('name', venueData?.name);
                formdata.append('description', venueData?.description);
                formdata.append('address', venueData.address);
                formdata.append('city', venueData.city);
                formdata.append('state', venueData.state);
                formdata.append('country', venueData.country);
                formdata.append('postcode', venueData.postcode);
                formdata.append('latitude', Number(venueData.lat));
                formdata.append('longitude', Number(venueData.long));
                formdata.append('timezone', venueData.timezone);
                if (isAdmin)
                    formdata.append('organizer_id', venueData.organizerId);

                for (let i = 0; i < sliderImg.length; i += 1) formdata.append('images', sliderImg[i]);

                for (let i = 0; i < venueData.amenity_selection.length; i += 1) formdata.append('amenity_ids[]', venueData.amenity_selection[i]);

                const response = await createVenue(formdata, {
                    Authorization: `Bearer ${window.localStorage.getItem(localStorageEnums.TOKEN)}`,
                });
                const data = await response.data;
                setSpinner(false);
                if (response.status === 201)
                    swal('Success', data.message, 'success').then(() => {
                        window.location.href = "/dashboard/venues";
                    });
            } else {
                setSliderImgError(sliderImg.length === 0);
                setErrors({
                    name: !venueData?.name?.trim(),
                    address: !venueData?.address?.trim(),
                    city: !venueData?.city?.trim(),
                    country: !venueData?.country?.trim(),
                    state: !venueData?.state?.trim(),
                    postcode: !venueData?.postcode?.trim(),
                    lat: !venueData?.lat,
                    timezone: !venueData?.timezone,
                    long: !venueData?.long,
                    amenity_selection: venueData?.amenity_selection.length === 0,
                    description: !venueData.description?.trim(),
                    organizerId: !venueData?.organizerId
                });
            }
        } catch (err) {
            setSpinner(false);
            console.log(err);
        }
    };
    return (
        <Page title="Create Venue">
            <Container>
                <SimpleBackdrop open={spinner} />
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Create Venue
                    </Typography>
                </Stack>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Card style={{ minHeight: '500px', padding: '20px' }}>
                            <Stack spacing={3} style={{ width: '100%' }}>
                                {isAdmin && <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <FormControl style={{ width: '100%' }} error={errors.organizerId}>
                                        <InputLabel id="demo-simple-select-label">Select an Organizer * </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={venueData.organizerId}
                                            label="Select an Organizer * "
                                            error={errors.organizerId}
                                            onChange={(e) => handleChange(e, 'organizerId')}
                                        >
                                            {organizersList.map(organizer => <MenuItem key={organizer?.userId} value={organizer?.userId}>{organizer?.name}</MenuItem>)}
                                            {organizersList?.length === 0 && <MenuItem value="" disabled>No organizers available.</MenuItem>}
                                        </Select>
                                        {errors.organizerId && <FormHelperText>{MESSAGES.ORGANIZER_REQUIRED}</FormHelperText>}
                                    </FormControl>
                                </Stack>}
                                <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                                    <TextField
                                        color="warning"
                                        label="Venue Name * "
                                        value={venueData?.name || ''}
                                        style={{ width: '100%' }}
                                        onChange={(e) => handleChange(e, 'name')}
                                        error={errors.name}
                                        helperText={errors.name && MESSAGES.VENUE_REQUIRED}
                                    />
                                </Stack>
                                <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                                    <div style={{ width: '100%' }}>
                                        <GooglePlacesAutocomplete
                                            className="google_autocomplete"
                                            apiKey={process.env.REACT_APP_GCP_KEY}
                                            autocompletionRequest={{
                                                componentRestrictions: {
                                                    country: ['us']
                                                }
                                            }}
                                            selectProps={{
                                                placeholder: "Search venue location...",
                                                isClearable: "true",
                                                styles: googleAutocompleteStyles,
                                                apiPlaceData,
                                                onChange: setApiPlaceData
                                            }}
                                        />
                                        <FormHelperText>Select Venue/location</FormHelperText>
                                    </div>
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        color="warning"
                                        label="Venue/ Location * "
                                        value={venueData?.address || ''}
                                        style={{ width: '100%' }}
                                        onChange={(e) => handleChange(e, 'address')}
                                        error={errors.address}
                                        helperText={errors.address && MESSAGES.VENUE_REQUIRED}
                                    />
                                    <TextField
                                        color="warning"
                                        label="City * "
                                        value={venueData?.city || ''}
                                        style={{ width: '100%' }}
                                        onChange={(e) => handleChange(e, 'city')}
                                        error={errors.city}
                                        helperText={errors.city && MESSAGES.CITY_REQUIRED}
                                    />
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        color="warning"
                                        label="State * "
                                        value={venueData?.state || ''}
                                        style={{ width: '100%' }}
                                        onChange={(e) => handleChange(e, 'state')}
                                        error={errors.state}
                                        helperText={errors.state && MESSAGES.STATE_REQUIRED}
                                    />
                                    <TextField
                                        color="warning"
                                        label="Zip code * "
                                        value={venueData?.postcode || ''}
                                        style={{ width: '100%' }}
                                        inputProps={{ maxLength: 6 }}
                                        onInput={(e) => {
                                            e.target.value = (e.target.value).toString().slice(0, 6);
                                        }}
                                        onChange={(e) => handleChange(e, 'postcode')}
                                        error={errors.postcode}
                                        type="text"
                                        helperText={errors.postcode && MESSAGES.POSTCODE_REQUIRED}
                                    />
                                    <TextField
                                        color="warning"
                                        label="Country * "
                                        value={venueData?.country || ''}
                                        style={{ width: '100%' }}
                                        onChange={(e) => handleChange(e, 'country')}
                                        error={errors.country}
                                        helperText={errors.country && MESSAGES.COUNTRY_REQUIRED}
                                    />
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <FormControl fullWidth error={errors.amenity_selection}>
                                        <InputLabel id="demo-multiple-checkbox-label">Amenities Selection * </InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            value={venueData?.amenity_selection}
                                            label="Amenities Selection * "
                                            error={errors?.amenity_selection}
                                            onChange={(e) => handleChange(e, 'amenity_selection')}
                                            multiple
                                            input={<OutlinedInput label="Amenities Selection * " />}
                                            renderValue={() => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {venueData?.amenity_selection?.map((value, index) => (
                                                        <p key={index} style={{ color: '#000' }}>
                                                            {amenities?.find((addon) => addon?.id === value)?.name}{' '}
                                                        </p>
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            <MenuItem
                                                value="all"
                                                classes={{
                                                    root: isAllSelected ? classes.selectedAll : ""
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        classes={{ indeterminate: classes.indeterminateColor }}
                                                        checked={isAllSelected}
                                                        indeterminate={
                                                            personName.length > 0 && personName.length < amenities.length
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    classes={{ primary: classes.selectAllText }}
                                                    primary="Select All"
                                                />
                                            </MenuItem>
                                            {amenities?.map((addon) => (
                                                <MenuItem
                                                    key={addon?.id}
                                                    value={addon?.id}
                                                    style={{
                                                        fontWeight: venueData?.amenity_selection?.find((add) => addon.id === add) ? '600' : '300',
                                                        backgroundColor: venueData?.amenity_selection?.find((add) => addon.id === add) ? '#faf5e6' : '#fff',
                                                    }}
                                                >
                                                    <Checkbox checked={personName?.indexOf(addon?.id) > -1} />
                                                    <ListItemText
                                                        primary={addon?.name}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.amenity_selection && <FormHelperText error={errors.amenity_selection}>
                                            {MESSAGES.AMENITIES_REQUIRED}
                                        </FormHelperText>}
                                    </FormControl>
                                </Stack>
                                <FormControl style={{ width: '100%' }} error={errors.timezone}>
                                    <InputLabel id="demo-simple-select-label">Timezone * </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={venueData?.timezone}
                                        label="Timezone * "
                                        error={errors.timezone}
                                        onChange={(e) => handleChange(e, 'timezone')}
                                    >
                                        <MenuItem value={'America/Chicago'}>CST (America/Chicago)</MenuItem>
                                        <MenuItem value={'Pacific/Honolulu'}>HST (Pacific/Honolulu)</MenuItem>
                                        <MenuItem value={'America/Anchorage'}>AKST (America/Anchorage)</MenuItem>
                                        <MenuItem value={'America/Los_Angeles'}>PST (America/Los_Angeles)</MenuItem>
                                        <MenuItem value={'MST7MDT'}>MST (MST7MDT)</MenuItem>
                                        <MenuItem value={'America/New_York'}>EST (America/New_York)</MenuItem>
                                    </Select>
                                    {errors.timezone && <FormHelperText>{MESSAGES.TIMEZONE_REQUIRED}</FormHelperText>}
                                </FormControl>
                                <TextField
                                    color="warning"
                                    label="Venue description"
                                    multiline
                                    inputProps={{ maxLength: 999 }}
                                    value={venueData?.description || ''}
                                    style={{ width: '100%' }}
                                    onChange={(e) => handleChange(e, 'description')}
                                />
                                <SliderUploadFiles setSliderImgState={setSliderImg} sliderImgState={sliderImg} />
                                {(sliderImgError && sliderImg.length === 0) && <FormHelperText error={sliderImgError}>{MESSAGES.IMAGES_REQUIRED}</FormHelperText>}
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
                <div className="save_button_div">
                    <LoadingButton
                        size="large"
                        type="button"
                        onClick={onSubmit}
                        variant="contained"
                        color="warning"
                        style={{ color: 'white', width: '50%' }}
                        loading={spinner}
                    >
                        Save
                    </LoadingButton>
                </div>
            </Container>
        </Page>
    );
};
